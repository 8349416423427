import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import vmSizesGpu from "../StaticDataJSON/vmSizesGpu.json"
import {
  Alert,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import StepContent from "@mui/material/StepContent";
import { AddCircleOutline, Delete } from "@mui/icons-material";

const steps = [
  "Azure Creds",
  "Resource Group INFO",
  "Cluster Info",
  "DNS and SSL Details",
];

// const VMSizes = [
//   "Standard_B2s",
//   "Standard_B2ms",
//   "Standard_B4ms",
//   "Standard_DS1_v2",
//   "Standard_DS2_v2",
//   "Standard_D4s_v3",
//   "Standard_D8s_v3",
//   "Standard_E2s_v3",
//   "Standard_E4s_v3",
//   "Standard_E8s_v3",
//   "Standard_E2s_v4",
//   "Standard_E4s_v4",
//   "Standard_F2s_v2",
//   "Standard_F4s_v2",
// ];

const VMSizes = ["Standard_B2s",
  "Standard_B2ms",
  "Standard_B4ms",
  "Standard_B8ms",
  "Standard_E4s_v3",
  "Standard_E8s_v3",
  "Standard_E16s_v3",
  "Standard_NC6s_v3",
  "Standard_NC12s_v3",
  "Standard_NC24s_v3",
  "Standard_NC4as_T4_v3",
  "Standard_NC8as_T4_v3",
  "Standard_NC16as_T4_v3",
  "Standard_NC64as_T4_v3",]

const WizardForm = ({ activeStep, setFormFelids, formFelids }) => {
  const handleFormChange = (e) => {
    setFormFelids({ ...formFelids, [e.target.name]: e.target.value });
  };
  const renderStepComponent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Grid container spacing={4}>
            <Grid item>
              <InputLabel id="demo-simple-select-label">
                Subscription ID
              </InputLabel>
              <TextField
                required
                id="outlined-basic"
                variant="outlined"
                name="SubscriptionID"
                value={formFelids.SubscriptionID}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item>
              <InputLabel id="demo-simple-select-label">Client ID</InputLabel>
              <TextField
                required
                id="outlined-basic"
                variant="outlined"
                name="ClientID"
                value={formFelids.ClientID}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item>
              <InputLabel id="demo-simple-select-label">
                Client Secret
              </InputLabel>
              <TextField
                required
                id="outlined-basic"
                variant="outlined"
                name="ClientSecret"
                value={formFelids.ClientSecret}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item>
              <InputLabel id="demo-simple-select-label">Tenant ID</InputLabel>
              <TextField
                required
                id="outlined-basic"
                variant="outlined"
                name="TenantID"
                value={formFelids.TenantID}
                onChange={handleFormChange}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={4}>
            <Grid item>
              <InputLabel>Location</InputLabel>
              <Select
                sx={{ minWidth: 180 }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formFelids.Location}
                name="Location"
                onChange={handleFormChange}
                required
              >
                <MenuItem value={"East US"}>East US</MenuItem>
                <MenuItem value={"West US"}>West US</MenuItem>
                <MenuItem value={"Canada Central"}>Central India</MenuItem>
                <MenuItem value={"West India"}>West India</MenuItem>
                <MenuItem value={"southindia"}>South India</MenuItem>
              </Select>
            </Grid>
            <Grid item>
              <InputLabel>Resource Group Name</InputLabel>
              <TextField
                id="outlined-basic"
                variant="outlined"
                name="ResourceGroupName"
                value={formFelids.ResourceGroupName}
                onChange={handleFormChange}
                required
              />

            </Grid>
            <Grid item>
              <InputLabel>Resource Group Already Present?</InputLabel>
              <Select
                sx={{ minWidth: 180 }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formFelids.hasResourceGroup}
                name="hasResourceGroup"
                onChange={handleFormChange}
                required
              >
                <MenuItem value={"false"}>Yes</MenuItem>
                <MenuItem value={"true"}>No</MenuItem>
              </Select>
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
            }}
          >
            <Grid container spacing={4}>
              <Grid item>
                <TextField
                  required
                  id="outlined-basic"
                  label="Cluster Name"
                  variant="outlined"
                  name="ClusterName"
                  value={formFelids.ClusterName}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item>
                <TextField
                  required
                  id="outlined-basic"
                  label="Node Count"
                  variant="outlined"
                  name="NodeCount"
                  value={formFelids.NodeCount}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item>
                <FormControl variant="outlined">
                  <InputLabel id="demo-simple-select-label">VM Size</InputLabel>

                  <Select
                    required
                    sx={{ minWidth: 180 }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formFelids.VMSize}
                    label="VM Size"
                    name="VMSize"
                    onChange={handleFormChange}
                  >
                    {VMSizes.map((size) => (
                      <MenuItem value={size}>{size}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <TextField
                  required
                  id="outlined-basic"
                  label="Storage"
                  variant="outlined"
                  name="Storage"
                  value={formFelids.Storage}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item>
                <TextField
                  required
                  id="outlined-basic"
                  label="Minimum Node Count"
                  variant="outlined"
                  name="MinNodeCount"
                  value={formFelids.MinNodeCount}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item>
                <TextField
                  required
                  id="outlined-basic"
                  label="Maximum Node Count"
                  variant="outlined"
                  name="MaxNodeCount"
                  value={formFelids.MaxNodeCount}
                  onChange={handleFormChange}
                />
              </Grid>
            </Grid>
            <Button
              style={{
                marginTop: "20px",
              }}
              onClick={() => {
                setFormFelids({
                  ...formFelids,
                  NodePools: [
                    ...formFelids.NodePools,
                    {
                      NodePoolName: `pool${formFelids.NodePools.length + 1}`,
                      NodeCount: 0,
                      VMSize: "",
                      storage: 0,
                      MinNodeCount: 0,
                      MaxNodeCount: 0,
                      autoScaling: false,
                    },
                  ],
                });
              }}
              variant="contained"
              startIcon={<AddCircleOutline />}
            >
              Add Node Pool
            </Button>
            {formFelids?.NodePools.map((nodePool, index) => {
              return (
                <>
                  <Typography
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    Node Pool {index + 1} Details
                  </Typography>
                  <Grid container spacing={2} marginTop={1}>
                    <Grid item>
                      <TextField
                        required
                        id="outlined-basic"
                        label="Node Pool Name"
                        variant="outlined"
                        name="ClusterName"
                        disabled
                        value={formFelids.NodePools[index].NodePoolName}
                      />
                    </Grid>
                    <Grid item>
                      <FormControl variant="outlined">
                        <InputLabel id="demo-simple-select-label">
                          Auto Scaling
                        </InputLabel>
                        <Select
                          sx={{ minWidth: "100px" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formFelids.NodePools[index].autoScaling}
                          label="Auto Scaling"
                          name="Auto scaling"
                          required
                          onChange={(e) => {
                            let temp = [...formFelids.NodePools];
                            temp[index].autoScaling = e.target.value;
                            setFormFelids({
                              ...formFelids,
                              NodePools: temp,
                            });
                          }}
                        >
                          <MenuItem value={"true"}>Yes</MenuItem>
                          <MenuItem value={"false"}>No</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <TextField
                        id="outlined-basic"
                        label="Node Count"
                        required
                        variant="outlined"
                        name="NodeCount"
                        value={formFelids.NodePools[index].NodeCount}
                        onChange={(e) => {
                          let temp = [...formFelids.NodePools];
                          temp[index].NodeCount = e.target.value;
                          setFormFelids({
                            ...formFelids,
                            NodePools: temp,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <FormControl variant="outlined">
                        <InputLabel id="demo-simple-select-label">
                          VM Size
                        </InputLabel>

                        <Select
                          sx={{ minWidth: 120 }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formFelids.NodePools[index].VMSize}
                          label="VM Size"
                          required
                          name="VMSize"
                          onChange={(e) => {
                            let temp = [...formFelids.NodePools];
                            temp[index].VMSize = e.target.value;
                            setFormFelids({
                              ...formFelids,
                              NodePools: temp,
                            });
                          }}
                        >
                          {VMSizes.map((size) => (
                            <MenuItem value={size}>{size}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <TextField
                        id="outlined-basic"
                        label="Storage"
                        variant="outlined"
                        name="Storage"
                        required
                        value={formFelids.NodePools[index].storage}
                        onChange={(e) => {
                          let temp = [...formFelids.NodePools];
                          temp[index].storage = e.target.value;
                          setFormFelids({
                            ...formFelids,
                            NodePools: temp,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        id="outlined-basic"
                        label="Minimum Node Count"
                        variant="outlined"
                        name="MinNodeCount"
                        required
                        value={formFelids.NodePools[index].MinNodeCount}
                        onChange={(e) => {
                          let temp = [...formFelids.NodePools];
                          temp[index].MinNodeCount = e.target.value;
                          setFormFelids({
                            ...formFelids,
                            NodePools: temp,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        id="outlined-basic"
                        label="Maximum Node Count"
                        variant="outlined"
                        name="MaxNodeCount"
                        value={formFelids.NodePools[index].MaxNodeCount}
                        onChange={(e) => {
                          let temp = [...formFelids.NodePools];
                          temp[index].MaxNodeCount = e.target.value;
                          setFormFelids({
                            ...formFelids,
                            NodePools: temp,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <IconButton
                        aria-label="delete"
                        size="large"
                        color="primary"
                        required
                        onClick={() => {
                          let temp = [...formFelids.NodePools];
                          temp.splice(index, 1);
                          setFormFelids({
                            ...formFelids,
                            NodePools: temp,
                          });
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>
                </>
              );
            })}
            <Button
              style={{
                marginTop: "20px",
              }}
              onClick={() => {
                setFormFelids({
                  ...formFelids,
                  GPUNodePools: [
                    ...formFelids.GPUNodePools,
                    {
                      NodePoolName: `gpu-pool${formFelids.GPUNodePools.length + 1}`,
                      NodeCount: 0,
                      VMSize: "",
                      storage: 0,
                      MinNodeCount: 0,
                      MaxNodeCount: 0,
                      autoScaling: false,
                    },
                  ],
                });
              }}
              variant="contained"
              startIcon={<AddCircleOutline />}
            >
              Add GPU Node Pool
            </Button>
            {formFelids?.GPUNodePools.map((gpuNodePool, index) => {
              return (
                <>
                  <Typography
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    GPU Node Pool {index + 1} Details
                  </Typography>
                  <Grid container spacing={2} marginTop={1}>
                    <Grid item>
                      <TextField
                        required
                        id="outlined-basic"
                        label="GPU Node Pool Name"
                        variant="outlined"
                        name="GPUClusterName"
                        disabled
                        value={formFelids.GPUNodePools[index].NodePoolName}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        id="outlined-basic"
                        label="Node Count"
                        required
                        variant="outlined"
                        name="NodeCount"
                        value={formFelids.GPUNodePools[index].NodeCount}
                        onChange={(e) => {
                          let temp = [...formFelids.GPUNodePools];
                          temp[index].NodeCount = e.target.value;
                          setFormFelids({
                            ...formFelids,
                            GPUNodePools: temp,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <FormControl variant="outlined">
                        <InputLabel id="demo-simple-select-label">
                          VM Size
                        </InputLabel>

                        <Select
                          sx={{ minWidth: 120 }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formFelids.GPUNodePools[index].VMSize}
                          label="VM Size"
                          required
                          name="VMSize"
                          onChange={(e) => {
                            let temp = [...formFelids.GPUNodePools];
                            temp[index].VMSize = e.target.value;
                            setFormFelids({
                              ...formFelids,
                              GPUNodePools: temp,
                            });
                          }}
                        >
                          {vmSizesGpu.map((size) => (
                            <MenuItem key={size.value} value={size.value}>{size.label}</MenuItem>))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <TextField
                        id="outlined-basic"
                        label="Storage"
                        variant="outlined"
                        name="Storage"
                        required
                        value={formFelids.GPUNodePools[index].storage}
                        onChange={(e) => {
                          let temp = [...formFelids.GPUNodePools];
                          temp[index].storage = e.target.value;
                          setFormFelids({
                            ...formFelids,
                            GPUNodePools: temp,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        id="outlined-basic"
                        label="Minimum Node Count"
                        variant="outlined"
                        name="MinNodeCount"
                        required
                        value={formFelids.GPUNodePools[index].MinNodeCount}
                        onChange={(e) => {
                          let temp = [...formFelids.GPUNodePools];
                          temp[index].MinNodeCount = e.target.value;
                          setFormFelids({
                            ...formFelids,
                            GPUNodePools: temp,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        id="outlined-basic"
                        label="Maximum Node Count"
                        variant="outlined"
                        name="MaxNodeCount"
                        value={formFelids.GPUNodePools[index].MaxNodeCount}
                        onChange={(e) => {
                          let temp = [...formFelids.GPUNodePools];
                          temp[index].MaxNodeCount = e.target.value;
                          setFormFelids({
                            ...formFelids,
                            GPUNodePools: temp,
                          });
                        }}
                      />
                    </Grid>

                    <Grid item>
                      <IconButton
                        aria-label="delete"
                        size="large"
                        color="primary"
                        required
                        onClick={() => {
                          let temp = [...formFelids.GPUNodePools];
                          temp.splice(index, 1);
                          setFormFelids({
                            ...formFelids,
                            GPUNodePools: temp,
                          });
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>
                </>
              );
            })}

          </div>
        );
      case 3:
        return (
          <Grid container spacing={4}>
            <Grid item>
              <InputLabel>Company Name</InputLabel>
              <TextField
                id="outlined-basic"
                variant="outlined"
                required
                name="CompanyName"
                value={formFelids.CompanyName}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item>
              <InputLabel>DNS Host name</InputLabel>
              <TextField
                id="outlined-basic"
                variant="outlined"
                required
                name="DNSHostname"
                value={formFelids.DNSHostname}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item>
              <InputLabel>Bundled CERT</InputLabel>
              <TextField
                id="outlined-basic"
                required
                variant="outlined"
                name="BundledCERT"
                value={formFelids.BundledCERT}
                onChange={handleFormChange}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item>
              <InputLabel>CERT Key</InputLabel>
              <TextField
                id="outlined-basic"
                required
                variant="outlined"
                name="CERTKey"
                value={formFelids.CERTKey}
                onChange={handleFormChange}
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
        );
      default:
        return <div>{activeStep + 1}</div>;
    }
  };

  return <React.Fragment>{renderStepComponent()}</React.Fragment>;
};

export default function HorizontalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const location = useLocation();

  const [locationStates, setLocationStates] = useState({
    requestId: location?.state.requestId,
    orgId:
      location.state.proposalId.endCustomerDetails.organization.organizationId,
    orgName: location.state.proposalId.endCustomerDetails.organization.name,
    orgCountry:
      location.state.proposalId.endCustomerDetails.organization.country,
    userId: location.state.proposalId.endCustomerDetails.user.userId,
    userName: location.state.proposalId.endCustomerDetails.user.name,
    userEmail: location.state.proposalId.endCustomerDetails.user.email,
    userUsername: location.state.proposalId.endCustomerDetails.user.username,
    subscriptionId: location.state.subscriptionId,
  });
  const [formFelids, setFormFelids] = React.useState({
    SubscriptionID: "",
    ClientID: "",
    ClientSecret: "",
    TenantID: "",
    Location: "",
    ResourceGroupName: "",
    ClusterName: "",
    NodeCount: "",
    VMSize: "",
    hasResourceGroup: false,
    Storage: "",
    MinNodeCount: "",
    MaxNodeCount: "",
    CompanyName: "",
    DNSHostname: "",
    BundledCERT: "",
    CERTKey: "",
    NodePools: [],
    GPUNodePools: [],
    helm: {
      subscriptionId: locationStates?.subscriptionId,
      org: {
        orgId: locationStates?.orgId,
        name: locationStates?.orgName,
        country: locationStates?.orgCountry,
      },
      user: {
        userId: locationStates?.userId,
        name: locationStates?.userName,
        email: locationStates?.userEmail,
        username: locationStates?.userUsername,
      },
    },
  });
  const [SnackBarOpen, setSnackBarOpen] = React.useState(false);
  const [SnackBarMessage, setSnackBarMessage] = React.useState(false);

  useEffect(() => {
    console.log(locationStates);
  }, [locationStates]);
  const stepperInputs = [
    ["SubscriptionID", "ClientID", "ClientSecret", "TenantID"],
    ["Location", "ResourceGroupName", "hasResourceGroup"],
    [
      "ClusterName",
      "NodeCount",
      "VMSize",
      "Storage",
      "MinNodeCount",
      "MaxNodeCount",
    ],
    ["CompanyName", "DNSHostname", "BundledCERT", "CERTKey"],
  ];
  const navigate = useNavigate();
  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (
      stepperInputs[activeStep]
        .map((key) => formFelids[key] === "")
        .includes(true)
    ) {
      console.log("Please fill all the fields");
      setSnackBarMessage("Please fill all the fields");
      setSnackBarOpen(true);
      return;
    }

    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const HandelFinish = () => {
    // check if all the fields are filled or not
    if (
      stepperInputs[activeStep]
        .map((key) => formFelids[key] === "")
        .includes(true)
    ) {
      setSnackBarMessage("Please fill all the fields");
      setSnackBarOpen(true);
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    axios
      .post("/api/v1/cluster/aks", { ...formFelids })
      .then((res) => {
        // console.log(res);
        navigate("/launcher/aks");
        return;
      })
      .catch((err) => {
        return;
      });
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: "#FFC000",
      },
    },
  });
  const handleSnackBarClose = (event, reason) => {
    setSnackBarOpen(false);
  };
  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        open={SnackBarOpen}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackBarClose} severity="error">
          {SnackBarMessage}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          width: "100%",
          bgcolor: "#fff",
          minHeight: "85vh",
        }}
      >
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          style={{
            backgroundColor: "#fff",
            padding: "40px",
          }}
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>
                  <Typography
                    variant="h6"
                    style={{
                      color: "#000",
                    }}
                  >
                    {label}
                  </Typography>
                </StepLabel>
                <StepContent>
                  <React.Fragment>
                    <Box
                      sx={{
                        display: "flex",
                        p: 8,
                      }}
                    >
                      <WizardForm
                        activeStep={activeStep}
                        setFormFelids={setFormFelids}
                        formFelids={formFelids}
                      />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      {isStepOptional(activeStep) && (
                        <Button
                          color="inherit"
                          onClick={handleSkip}
                          sx={{ mr: 1 }}
                        >
                          Skip
                        </Button>
                      )}

                      {activeStep === steps.length - 1 ? (
                        <Button variant="contained" onClick={HandelFinish}>
                          Finish
                        </Button>
                      ) : (
                        <Button variant="contained" onClick={handleNext}>
                          Next
                        </Button>
                      )}
                    </Box>
                  </React.Fragment>
                </StepContent>
              </Step>
            );
          })}
          {activeStep === steps.length && (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          )}
        </Stepper>
      </Box>
    </ThemeProvider>
  );
}
