import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CachedOutlined, Add } from "@mui/icons-material";

import Searchbar from "../../Components/Element/Searchbar/Searchbar";
import BlockBox from "../../Components/Element/Box/Box";
import StyledButton from "../../Components/Element/Button/Button";
import "./Request.css";
import SnackbarComponent from "../../Components/Element/Snackbar/Snackbar";
import Table from "../../Components/Element/Table/Table";
import SpringModal from "../../Components/Element/Modal/Modal";
import ViewMore from "./ViewMore/ViewMore";
import { styled } from "@mui/material/styles";

const tableHeaders = [
  { name: "Catalogue", type: "label", key: "catalogue" },
  { name: "Customer Admin", type: "label", key: "proposal_by" },
  {
    name: "Customer Organization",
    type: "label",
    key: "proposal_organization",
  },
  { name: "Status", type: "label", key: "status" },
  { name: "Action", type: "action", key: "action" },
];

const Request = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    message: "",
    severity: "success",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [rowIndex, setRowIndex] = useState({});
  const [openRow, setOpenRow] = useState(false);

  const [clusterData, setClusterData] = useState([]);

  const navigate = useNavigate();
  const handleCreateCluster = () => {
    navigate("create");
  };

  const showDetails = (e) => {
    handleViewToggle();
    setRowIndex(e);
  };

  const handleViewToggle = () => {
     setOpenRow(!openRow);
  };

  const getRequestData = async () => {
    setIsLoading(true);
    axios.get("/api/v1/request").then((res) => {
      const data = res.data.data.map((item) => {
        return {
          catalogue: item.catalogueId.name,
          proposal_by: item.proposalId.endCustomerDetails.user.name,
          proposal_organization:
            item.proposalId.endCustomerDetails.organization.name,
          ...item,
        };
      });
      setSnackbarData({
        message: "Data Fetched Successfully",
        severity: "success",
      });
      setSnackbarOpen(true);
      setClusterData(data);
    });
    setIsLoading(false);
  };

  const refreshRequestData = async () => {
    setIsLoading(true);
    axios.get("/api/v1/request/refresh").then((res) => {
      const data = res.data.data.map((item) => {
        return {
          catalogue: item.catalogueId.name,
          proposal_by: item.proposalId.endCustomerDetails.user.name,
          proposal_organization:
            item.proposalId.endCustomerDetails.organization.name,
          requestType:item.requestType,
          ...item,
        };
      });
      setSnackbarData({
        message: "Data Fetched Successfully",
        severity: "success",
      });
      setSnackbarOpen(true);
      setClusterData(data);
    });
    setIsLoading(false);
  };

  useEffect(() => {
    getRequestData();
  }, []);

  return (
    <div>
      <SnackbarComponent
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarData.message}
        severity={snackbarData.severity}
      />
      <SpringModal
        styleModal={{ width: "80vw", lgWidth: "80vw", mdWidth: "97vw" }}
        open={openRow}
        setOpen={setOpenRow}
      >
        <ViewMore
          tableData={clusterData}
          rowIndex={rowIndex}
          toggleOpenState={handleViewToggle}
        />
      </SpringModal>
      <div className="server-main">
        <div className="server-box">
          <BlockBox>
            <div className="server-block-header">
              <div className="server-box-title">All Requests</div>
              <div className="server-block-header-right">
                <div style={{ marginRight: "15px" }}>
                  <Searchbar />
                </div>
                <StyledButton
                  styleDetails={{
                    padding: "9px 15px",
                    borderRadius: "10px",
                    bgcolor: "#881630",
                    bghover: "#ffc000",
                    margin: "0 5px",
                  }}
                  name={"Refresh"}
                  endIcon={<CachedOutlined />}
                  onClickFunc={refreshRequestData}
                />
              </div>
            </div>

            <div className="server-table-cover">
              <Table
                columns={tableHeaders}
                showDetails={showDetails}
                loading={isLoading}
                column={true}
                rows={clusterData}
              />
            </div>
          </BlockBox>
        </div>
      </div>
    </div>
  );
};

export default Request;
