import axios from "axios";

export async function handleReset(password, confirmPassword, resetToken, navigation, setSnackbarOpen, setSnackbarData) {
    if (password !== confirmPassword) {
        setSnackbarOpen(true);
        setSnackbarData({
            message: "Passwords must match",
            severity: "error"
        });
        return;
    }

    try {
        await axios.post("/api/v1/auth/resetpassword", {
            password,
            resetToken
        });
        setSnackbarOpen(true);
        setSnackbarData({
            message: "Password reset successfully",
            severity: "success"
        });

        setTimeout(() => {
            navigation("/");
        }, 2000);
        
    } catch (e) {
        setSnackbarOpen(true);
        setSnackbarData({
            message: `Error occurred: ${e.message}`,
            severity: "error"
        });

        setTimeout(() => {
            setSnackbarOpen(false);
        }, 3000);

        console.log(e);
    }
}