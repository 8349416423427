import React, { useEffect, useState } from "react";
import StyledButton from "../../../Components/Element/Button/Button";
import Table from "../../../Components/Element/Table/Table";
import "./ChooseCluster.css";
import axios from "axios";
import Stepper from "../../../Components/Element/Stepper/Stepper";
import SelectCluster from "./Steps/SelectCluster/SelectCluster";
import DomainName from "./Steps/DomainName/DomainName";


const ChooseCluster = (props) => {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [clusterSelected, setClusterSelected] = useState([]);
    const [domainName, setDomainName] = useState("");
    const [certFile, setCertFile] = useState("");
    const [keyFile, setKeyFile] = useState("");
    const aiMicroCloudSteps = [
        {
            label: "Select Cluster for Micro Cloud Installation",
            content: (
                <SelectCluster
                    setCheckBoxSelected={setClusterSelected}
                    checkBoxSelected={clusterSelected}
                    tableData={tableData}
                />
            ),
        }, {
            label: "Domain Details",
            content: (
                <DomainName
                    setDomainName={setDomainName}
                    setCertFile={setCertFile}
                    setKeyFile={setKeyFile}
                />
            )
        },
    ];
    const getActiveClusterForMicroCloud = () => {
        axios
            .get("/api/v1/cluster/baremetal", {
                params: {
                    status: true,
                    healthCheck: true,
                    isAiMicroCloudInstalled: false,
                },
            })
            .then((res) => {
                const data = res.data.data.map((cluster) => {
                    return {
                        id: cluster._id,
                        k8sId: cluster.k8sId,
                        k8sClusterName: cluster.k8sClusterName,
                    };
                });
                setTableData(data);
            });
    };

    function handleMicroCloudInstall() {
        axios.post("/api/v1/cluster/baremetal/installMicrocloud", {
            clusterID: clusterSelected,
            domainName:domainName,
            certFile:certFile,
            keyFile:keyFile
        }).then((res) => {
            console.log(res);
        }).catch((err) => {
            console.log(err);
            alert("Error in installing MicroCloud");
            return;
        });
        setIsLoading(false)
        props.setOpen(false)
        console.log("Done");
    }

    useEffect(() => {
        getActiveClusterForMicroCloud();
    }, []);

    return (
        <Stepper
            finishCallback={handleMicroCloudInstall}
            steps={aiMicroCloudSteps}
            currentMaxSteps={
                (clusterSelected.length === 1) + (keyFile != "")
                +1 
            }
            setOpen={props.setOpen}
        />
    );
};

export default ChooseCluster;
