import React, { useEffect, useState } from "react";
import "./Login.css";
import left_image from "../../Assets/Images/mainImage.svg";
import logo from "../../Assets/Images/logo.svg";
import Input from "../../Components/Element/Input/Input";
import { Lock, Email, Person, Person4Outlined, People, Group } from "@mui/icons-material";
import StyledButton from "../../Components/Element/Button/Button";
import { ExitToApp } from "@mui/icons-material";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { handleLogin, handleMarketPlaceSignup } from "./LoginFunction";
import SnackbarComponent from "../../Components/Element/Snackbar/Snackbar";

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    "& span": {
        [theme.breakpoints.down("sm")]: {
            fontSize: "10px",
        },
    },
}));

const MarketPlaceLogin = () => {
    const toggle = () => {
        setIsLogin(!isLogin);
    }
    const navigation = useNavigate();
    const [isLogin, setIsLogin] = useState(true);
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [fullname, setFullName] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarData, setSnackbarData] = useState({ message: "", severity: "success" })
    const [organization, setOrganization] = useState("")
    const [awsId, setawsId] = useState("")

    // useEffect(() => {
    //     const token = localStorage.getItem("login_token")
    //     if (token) navigation("/launcher/eks");
    // })
    return (
        <div className="login-window">
            <SnackbarComponent open={snackbarOpen} setOpen={setSnackbarOpen} message={snackbarData.message}
                severity={snackbarData.severity} />
            <div className="login-box login-left">
                <div className="login-content">
                    <img src={logo} alt="Zeblok Logo" className="login-logo" />
                    <div className="login-tagline">
                        Zeblok is now an intel Partner Alliance member
                    </div>
                    <img
                        src={left_image}
                        alt="zeblok image"
                        className="login-left-image"
                    />
                </div>
            </div>
            <div className="login-box login-right">
                {isLogin ? (
                    <div className="login-content login-form">
                        <div className="login-welcome-title">Welcome Back</div>
                        <div className="login-requirement-line">
                            Please enter your details below to login
                        </div>
                        <Input
                            type={"email"}
                            name={"Email"}
                            id={"login-email"}
                            icon={<Email />}
                            placeholder={"Enter email or username"}
                            callback={setUserName}
                            styled={{ padding: "10px", borderRadius: "5px", margin: "10px 0" }}
                        />
                        <Input
                            type={"password"}
                            name={"Password"}
                            id={"login-password"}
                            icon={<Lock />}
                            placeholder={"Password"}
                            callback={setPassword}
                            styled={{ padding: "10px", borderRadius: "5px", margin: "10px 0" }}
                        />
                        <div className="login-remember-section">
                            <FormGroup>
                                <StyledFormControlLabel
                                    control={<Checkbox defaultChecked />}
                                    label="Remember Me?"
                                />
                            </FormGroup>
                            <button className="login-forgotPassword">Forgot Password?</button>
                        </div>
                        <div className="login-btn"
                            onClick={() => handleLogin(email, password, navigation, setSnackbarOpen, setSnackbarData)}>
                            <StyledButton
                                styleDetails={{
                                    padding: "9px 15px",
                                    borderRadius: "10px",
                                    bgcolor: "#881630",
                                    bghover: "#ffc000",
                                }}
                                name={"Login"}
                                endIcon={<ExitToApp />}
                            />
                        </div>
                        <div className="login-already-account">
                            Not registered yet?{" "}
                            <span className="login-span" onClick={() => toggle()}>Create an Account</span>
                        </div>
                    </div>
                ) : (
                    <div className="login-content login-form">
                        <div className="login-welcome-title">Create an Account</div>
                        <div className="login-requirement-line">
                            Please enter your details below to create account
                        </div>
                        <Input
                            type={"text"}
                            name={"Full Name"}
                            id={"signup-name"}
                            icon={<Person />}
                            placeholder={"Enter your full name"}
                            callback={setFullName}
                            styled={{ padding: "10px", borderRadius: "5px", margin: "10px 0" }}
                        />
                        <Input
                            type={"text"}
                            name={"Username"}
                            id={"signup-username"}
                            icon={<Person4Outlined />}
                            placeholder={"Enter email or username"}
                            callback={setUserName}
                            styled={{ padding: "10px", borderRadius: "5px", margin: "10px 0" }} x
                        />
                        <Input
                            type={"email"}
                            name={"Email"}
                            id={"signup-email"}
                            icon={<Email />}
                            placeholder={"Enter email or username"}
                            callback={setEmail}
                            styled={{ padding: "10px", borderRadius: "5px", margin: "10px 0" }}
                        />
                        <Input
                            type={"password"}
                            name={"Password"}
                            id={"signup-password"}
                            icon={<Lock />}
                            placeholder={"Password"}
                            callback={setPassword}
                            styled={{ padding: "10px", borderRadius: "5px", margin: "10px 0" }}
                        />

                        <Input
                            type={"organization"}
                            name={"Organization"}
                            id={"signup-organization"}
                            icon={<People />}
                            placeholder={"Organization"}
                            callback={setOrganization}
                            styled={{ padding: "10px", borderRadius: "5px", margin: "10px 0" }}
                        />

                        <Input
                            type={"awsId"}
                            name={"AWS Account ID"}
                            id={"signup-awsId"}
                            icon={<Group />}
                            placeholder={"AWS ID"}
                            callback={setawsId}
                            styled={{ padding: "10px", borderRadius: "5px", margin: "10px 0" }}
                            description={"This should be same as the one you subscribed with"}
                        />
                        <div className="login-remember-section">
                            <FormGroup>
                                <StyledFormControlLabel
                                    control={<Checkbox defaultChecked />}
                                    label="Remember Me?"
                                />
                            </FormGroup>
                        </div>
                        <div className="login-btn"
                            onClick={() => handleMarketPlaceSignup({ name: fullname, username, email, password, organization, awsId }, navigation, setSnackbarOpen, setSnackbarData)}>
                            <StyledButton
                                styleDetails={{
                                    padding: "9px 15px",
                                    borderRadius: "10px",
                                    bgcolor: "#881630",
                                    bghover: "#ffc000",
                                }}
                                name={"Sign Up"}
                                endIcon={<ExitToApp />}
                            />
                        </div>
                        <div className="login-already-account">
                            Already Have a Account?{" "}
                            <span className="login-span" onClick={() => toggle()}>Login</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MarketPlaceLogin;
