import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {
  Alert,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import StepContent from "@mui/material/StepContent";
import { AddCircleOutline, Delete } from "@mui/icons-material";
import awsRegion from "../StaticDataJSON/awsRegion.json";
import vpcInfo from "../StaticDataJSON/vpc.json";
import { Stack } from "@mui/system";
import InstanceType from "../StaticDataJSON/instanceTypes.json";
import k8sVersionInfo from "../StaticDataJSON/k8sVersion.json";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSelector } from "react-redux";

const steps = [
  "AWS Client Info",
  "Basic Info",
  "Node Settings",
  "DNS and SSL Details",
];

const VMSizes = [
  "Standard_B2s",
  "Standard_B2ms",
  "Standard_B4ms",
  "Standard_DS1_v2",
  "Standard_DS2_v2",
  "Standard_D4s_v3",
  "Standard_D8s_v3",
  "Standard_E2s_v3",
  "Standard_E4s_v3",
  "Standard_E8s_v3",
  "Standard_E2s_v4",
  "Standard_E4s_v4",
  "Standard_F2s_v2",
  "Standard_F4s_v2",
];

const WizardForm = ({ activeStep, setFormFelids, formFelids }) => {
  const [optionsSelected, setOptionsSelected] = useState(false);
  const userType = useSelector((state) => state.user.userType);
  const subscriptionType = useSelector(
    (state) => state.user.subscriptionDetails[0].Dimension
  );
  const handleFormChange = (e) => {
    setFormFelids({ ...formFelids, [e.target.name]: e.target.value });
  };
  const renderStepComponent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Grid container spacing={4}>
            <Grid item>
              <InputLabel id="demo-simple-select-label">
                AWS Account Id
              </InputLabel>
              <TextField
                required
                id="outlined-basic"
                variant="outlined"
                name="aws_account_id"
                value={formFelids.aws_account_id}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item>
              <InputLabel id="demo-simple-select-label">Location</InputLabel>
              <Select
                sx={{ minWidth: 180 }}
                id="demo-simple-select"
                value={formFelids.Location}
                name="region"
                onChange={handleFormChange}
                required
              >
                {awsRegion.map((item) => {
                  return <MenuItem value={item.id}>{item.value}</MenuItem>;
                })}
              </Select>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={4}>
            <Grid item>
              <InputLabel id="demo-simple-select-label">
                Cluster Name
              </InputLabel>
              <TextField
                id="outlined-basic"
                variant="outlined"
                name="cluster_name"
                value={formFelids.ResourceGroupName}
                onChange={handleFormChange}
                required
                placeholder="Use only aA-zZ, and _"
              />
            </Grid>
            <Grid item>
              <InputLabel id="demo-simple-select-label">K8s Version</InputLabel>
              <Select
                sx={{ minWidth: 180 }}
                id="demo-simple-select"
                value={formFelids.K8sVersion}
                name="cluster_version"
                onChange={handleFormChange}
                required
              >
                {k8sVersionInfo.map((item) => {
                  return <MenuItem value={item.id}>{item.value}</MenuItem>;
                })}
              </Select>
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
            }}
          >
            <Grid container spacing={4}>
              {userType !== "marketplace" && (
                <Grid item>
                  <TextField
                    required
                    id="outlined-basic"
                    label="Node Group Name"
                    variant="outlined"
                    name="NodeGroupName"
                    value={formFelids.NodeCount}
                    onChange={handleFormChange}
                    placeholder="same as cluster name"
                  />
                </Grid>
              )}
              <Grid item>
                <FormControl variant="outlined">
                  <InputLabel id="demo-simple-select-label">
                    Instances Type
                  </InputLabel>

                  <Select
                    required
                    sx={{ minWidth: 180 }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={
                      userType !== "marketplace"
                        ? formFelids.instance_types
                        : [
                            subscriptionType === "basic_large"
                              ? "g2.2xlarge"
                              : "t2.medium",
                          ]
                    }
                    multiple
                    label="Instance Types"
                    name="instance_types"
                    onChange={(e) => {
                      const { value } = e.target;
                      console.log(value);
                      if (formFelids.instance_types.length === 3) {
                        alert("You can select only 3 Instance Types");
                        return;
                      }
                      setFormFelids({
                        ...formFelids,
                        instance_types: value,
                      });
                    }}
                    input={<OutlinedInput label="Multiple Select" />}
                    disabled={userType === "marketplace"}
                    renderValue={(selected) => (
                      <Stack gap={1} direction="row" flexWrap="wrap">
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            onDelete={() => {
                              setFormFelids({
                                ...formFelids,
                                instance_types:
                                  formFelids.instance_types.filter(
                                    (item) => item !== value
                                  ),
                              });
                            }}
                            deleteIcon={
                              <CancelIcon
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            }
                          />
                        ))}
                      </Stack>
                    )}
                  >
                    {InstanceType.map((size) => (
                      <MenuItem value={size.id}>{size.value}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {userType !== "marketplace" && (
                <Grid item>
                  <TextField
                    required
                    id="outlined-basic"
                    label="Storage"
                    variant="outlined"
                    name="Storage"
                    value={formFelids.Storage}
                    onChange={handleFormChange}
                    placeholder="More than 30"
                  />
                </Grid>
              )}
              <Grid item>
                <TextField
                  required
                  id="outlined-basic"
                  label="Desired Node"
                  variant="outlined"
                  name="desired_size"
                  value={
                    userType === "marketplace" ? 3 : formFelids.desired_size
                  }
                  onChange={handleFormChange}
                  disabled={userType === "marketplace"}
                />
              </Grid>

              <Grid item>
                <TextField
                  required
                  id="outlined-basic"
                  label="Minimum Node"
                  variant="outlined"
                  name="MinNodeCount"
                  value={
                    userType === "marketplace" ? 3 : formFelids.MinNodeCount
                  }
                  onChange={handleFormChange}
                  disabled={userType === "marketplace"}
                />
              </Grid>
              <Grid item>
                <TextField
                  required
                  id="outlined-basic"
                  label="Maximum Node"
                  variant="outlined"
                  name="MaxNodeCount"
                  value={
                    userType === "marketplace" ? 3 : formFelids.MaxNodeCount
                  }
                  onChange={handleFormChange}
                  disabled={userType === "marketplace"}
                />
              </Grid>
            </Grid>
            {userType !== "marketplace" && (
              <Button
                style={{
                  marginTop: "20px",
                }}
                onClick={() => {
                  setFormFelids({
                    ...formFelids,
                    NodePools: [
                      ...formFelids.NodePools,
                      {
                        NodeName: `nodegroup${formFelids.NodePools.length + 1}`,
                        instance_types: [],
                        desired_size: 0,
                        max_size: 0,
                        storage: 0,
                        min_size: 0,
                      },
                    ],
                  });
                }}
                variant="contained"
                startIcon={<AddCircleOutline />}
              >
                Add Node Group
              </Button>
            )}
            {formFelids?.NodePools.map((nodePool, index) => {
              return (
                <>
                  <Typography
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    Node Group {index + 1} Details
                  </Typography>
                  <Grid container spacing={2} marginTop={1}>
                    <Grid item>
                      <TextField
                        required
                        id="outlined-basic"
                        label="Node Name"
                        variant="outlined"
                        name="NodeName"
                        disabled
                        value={nodePool.NodeName}
                      />
                    </Grid>
                    <Grid item>
                      <FormControl variant="outlined">
                        <InputLabel id="demo-simple-select-label">
                          Instance Types
                        </InputLabel>
                        <Select
                          required
                          sx={{ minWidth: 280 }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={nodePool.instance_types}
                          multiple
                          name="VMSize"
                          onChange={(e) => {
                            if (nodePool.instance_types.length == 3) {
                              return;
                            }
                            const { value } = e.target;
                            setFormFelids({
                              ...formFelids,
                              NodePools: formFelids.NodePools.map((item, i) => {
                                if (i === index) {
                                  return {
                                    ...item,
                                    instance_types: value,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          input={<OutlinedInput label="Multiple Select" />}
                          renderValue={(selected) => (
                            <Stack gap={1} direction="row" flexWrap="wrap">
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={value}
                                  onDelete={() => {
                                    setFormFelids({
                                      ...formFelids,
                                      NodePools: formFelids.NodePools.map(
                                        (item, i) => {
                                          if (i === index) {
                                            return {
                                              ...item,
                                              instance_types:
                                                item.instance_types.filter(
                                                  (size) => size !== value
                                                ),
                                            };
                                          }
                                          return item;
                                        }
                                      ),
                                    });
                                  }}
                                  deleteIcon={
                                    <CancelIcon
                                      onMouseDown={(event) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                />
                              ))}
                            </Stack>
                          )}
                        >
                          {InstanceType.map((size) => (
                            <MenuItem value={size.id}>{size.value}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <TextField
                        required
                        id="outlined-basic"
                        label="Desired Node"
                        variant="outlined"
                        value={nodePool.desired_size}
                        onChange={(e) => {
                          let temp = [...formFelids.NodePools];
                          temp[index].desired_size = e.target.value;
                          setFormFelids({
                            ...formFelids,
                            NodePools: temp,
                          });
                        }}
                      />
                    </Grid>{" "}
                    <Grid item>
                      <TextField
                        required
                        id="outlined-basic"
                        label="Minimum Node"
                        variant="outlined"
                        value={nodePool.min_size}
                        onChange={(e) => {
                          let temp = [...formFelids.NodePools];
                          temp[index].min_size = e.target.value;
                          setFormFelids({
                            ...formFelids,
                            NodePools: temp,
                          });
                        }}
                      />
                    </Grid>{" "}
                    <Grid item>
                      <TextField
                        required
                        id="outlined-basic"
                        label="Maximum Node"
                        variant="outlined"
                        name="MinNodeCount"
                        value={nodePool.max_size}
                        onChange={(e) => {
                          let temp = [...formFelids.NodePools];
                          temp[index].max_size = e.target.value;
                          setFormFelids({
                            ...formFelids,
                            NodePools: temp,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <IconButton
                        aria-label="delete"
                        size="large"
                        color="primary"
                        required
                        onClick={() => {
                          let temp = [...formFelids.NodePools];
                          temp.splice(index, 1);
                          setFormFelids({
                            ...formFelids,
                            NodePools: temp,
                          });
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>
                </>
              );
            })}
          </div>
        );
      case 3:
        return (
          <Grid container spacing={4}>
            <Grid item>
              <TextField
                id="outlined-basic"
                label="Company Name"
                variant="outlined"
                required
                name="CompanyName"
                value={formFelids.CompanyName}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item>
              <TextField
                id="outlined-basic"
                label="Domain Name"
                variant="outlined"
                required
                name="DNSHostname"
                value={formFelids.DNSHostname}
                onChange={handleFormChange}
                placeholder="e.g: abc@xyz.com"
              />
            </Grid>
            <Grid item>
              <TextField
                id="outlined-basic"
                label=" Certificate chain"
                required
                variant="outlined"
                name="BundledCERT"
                value={formFelids.BundledCERT}
                onChange={handleFormChange}
                multiline
                rows={4}
                placeholder="CA BUNDLE: A CA bundle can be created from multiple CRT (Certificate files) by concatenating them into a single files. On how to create a bundle crt file please visit our documentation page."
              />
            </Grid>
            <Grid item>
              <TextField
                id="outlined-basic"
                label="Certificate private key"
                required
                variant="outlined"
                name="CERTKey"
                value={formFelids.CERTKey}
                onChange={handleFormChange}
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
        );
      default:
        return <div>{activeStep + 1}</div>;
    }
  };

  return <React.Fragment>{renderStepComponent()}</React.Fragment>;
};

export default function EKSClusterForm() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [SnackBarOpen, setSnackBarOpen] = React.useState(false);
  const [SnackBarMessage, setSnackBarMessage] = React.useState(false);
  const location = useLocation();

  const userType = useSelector((state) => state.user.userType);
  const subscriptionType = useSelector(
    (state) => state.user.subscriptionDetails[0].Dimension
  );
  const [locationStates, setLocationStates] = useState({
    requestId: location?.state.requestId,
    orgId:
      location.state.proposalId.endCustomerDetails.organization.organizationId,
    orgName: location.state.proposalId.endCustomerDetails.organization.name,
    orgCountry:
      location.state.proposalId.endCustomerDetails.organization.country,
    userId: location.state.proposalId.endCustomerDetails.user.userId,
    userName: location.state.proposalId.endCustomerDetails.user.name,
    userEmail: location.state.proposalId.endCustomerDetails.user.email,
    userUsername: location.state.proposalId.endCustomerDetails.user.username,
    subscriptionId: location.state.subscriptionId,
  });
  const [formFelids, setFormFelids] = React.useState({
    // AWSkey: "",
    // AWSSecret: "",
    aws_account_id: "",
    region: "",
    cluster_name: "",
    cluster_version: "",
    NodeGroupName: "",
    instance_types: [],
    Storage: "",
    MinNodeCount: "",
    MaxNodeCount: "",
    desired_size: "",

    CompanyName: "",
    DNSHostname: "",
    BundledCERT: "",
    CERTKey: "",
    NodePools: [],
    helm: {
      subscriptionId: locationStates?.subscriptionId,
      org: {
        orgId: locationStates?.orgId,
        name: locationStates?.orgName,
        country: locationStates?.orgCountry,
        requestId: locationStates?.requestId,
      },
      user: {
        userId: locationStates?.userId,
        name: locationStates?.userName,
        email: locationStates?.userEmail,
        username: locationStates?.userUsername,
      },
    },
  });
  const stepperInputs = [
    ["aws_account_id", "region"],
    ["cluster_name", "cluster_version"],
    [
      "NodeGroupName",
      "instance_types",
      "Storage",
      "MinNodeCount",
      "MaxNodeCount",
      "desired_size",
    ],
    ["CompanyName", "DNSHostname", "BundledCERT", "CERTKey"],
  ];

  const navigate = useNavigate();
  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    console.log(stepperInputs[activeStep]);
    if (
      stepperInputs[activeStep]
        .map((key) => formFelids[key].length === 0)
        .includes(true) &&
      userType !== "marketplace"
    ) {
      console.log("Please fill all the fields");
      setSnackBarMessage("Please fill all the fields");
      setSnackBarOpen(true);
      return;
    }
    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const HandelFinish = () => {
    if (
      stepperInputs[activeStep]
        .map((key) => formFelids[key].length === 0)
        .includes(true) &&
      userType !== "marketplace"
    ) {
      console.log("Please fill all the fields");
      setSnackBarMessage("Please fill all the fields");
      setSnackBarOpen(true);
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    console.log(formFelids);
    if (userType === "marketplace") {
      formFelids["userType"] = userType;
      console.log(subscriptionType);

      if (subscriptionType === "basic_large") {
        setFormFelids({
          ...formFelids,
          instance_types: formFelids.instance_types.push("g2.2xlarge"),
        });
      } else {
        setFormFelids({
          ...formFelids,
          instance_types: formFelids.instance_types.push("t2.medium"),
        });
      }
    }
    axios
      .post("/api/v1/cluster/eks", { ...formFelids })
      .then((res) => {
        // console.log(res);
        navigate("/launcher/eks");
        return;
      })
      .catch((err) => {
        return;
      });
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: "#FFC000",
      },
    },
  });
  const handleSnackBarClose = (event, reason) => {
    setSnackBarOpen(false);
  };
  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        open={SnackBarOpen}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackBarClose} severity="error">
          {SnackBarMessage}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          width: "100%",
          bgcolor: "#fff",
          minHeight: "85vh",
        }}
      >
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          style={{
            backgroundColor: "#fff",
            padding: "40px",
          }}
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>
                  <Typography
                    variant="h6"
                    style={{
                      color: "#000",
                    }}
                  >
                    {label}
                  </Typography>
                </StepLabel>
                <StepContent>
                  <React.Fragment>
                    <Box
                      sx={{
                        display: "flex",
                        p: 8,
                      }}
                    >
                      <WizardForm
                        activeStep={activeStep}
                        setFormFelids={setFormFelids}
                        formFelids={formFelids}
                      />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      {isStepOptional(activeStep) && (
                        <Button
                          color="inherit"
                          onClick={handleSkip}
                          sx={{ mr: 1 }}
                        >
                          Skip
                        </Button>
                      )}

                      {activeStep === steps.length - 1 ? (
                        <Button variant="contained" onClick={HandelFinish}>
                          Finish
                        </Button>
                      ) : (
                        <Button variant="contained" onClick={handleNext}>
                          Next
                        </Button>
                      )}
                    </Box>
                  </React.Fragment>
                </StepContent>
              </Step>
            );
          })}
          {activeStep === steps.length && (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          )}
        </Stepper>
      </Box>
    </ThemeProvider>
  );
}
