import React, {useEffect, useState} from "react";
import "./AddCluster.css";
import axios from "axios";
import Stepper from "../../../Components/Element/Stepper/Stepper";
import ClusterDetails from "./Steps/ClusterDetails/ClusterDetails";
import MasterServers from "./Steps/MasterServers/MasterServer";
import WorkerServers from "./Steps/WorkerServers/WorkerServers";
import VirtualIPDetails from "./Steps/VirtualIPDetails/VirtualIPDetails";
import ConfigureStorage from "./Steps/ConfigureStorage/ConfigureStorage";

const AddCluster = (props) => {
    const [kubernetesclusterId, setKubernetesClusterId] = useState("");
    const [kubernetesclusterName, setKubernetesClusterName] = useState("");
    const [kubernetesElasticIP, setKubernetesElasticIP] = useState("");
    const [diskPathStorage, setDiskPathStorage] = useState("");
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [masterSelected, setMasterSelected] = useState([]);
    const [workerSelected, setWorkerSelected] = useState([]);
    const createClusterSteps = [
        {
            label: "Enter Clusters Detail",
            content: (
                <ClusterDetails
                    setKubernetesClusterId={setKubernetesClusterId}
                    setKubernetesClusterName={setKubernetesClusterName}
                />
            ),
        },
        {
            label: "Select 3 Master Servers",
            content: (
                <MasterServers
                    setCheckBoxSelected={setMasterSelected}
                    checkBoxSelected={masterSelected}
                    tableData={tableData}
                />
            ),
        },
        {
            label: "Select Worker Servers",
            content: (
                <WorkerServers
                    setCheckBoxSelected={setWorkerSelected}
                    checkBoxSelected={workerSelected}
                    tableData={tableData.filter(
                        (val) => masterSelected.indexOf(val.id) === -1
                    )}
                />
            ),
        },
        {
            label: "Enter disk path to be included in the storage",
            content: (
                <ConfigureStorage
                    setDiskPathStorage={setDiskPathStorage}
                />
            ),
        },
        {
            label: "Enter Virtual IP Details",
            content: (
                <VirtualIPDetails
                    setKubernetesElasticIP={setKubernetesElasticIP}
                />
            ),
        },

    ];


    const getActiveServersForKuber = () => {
        axios
            .get("/api/v1/server", {
                params: {
                    isActive: true,
                    // osInstallationStatus: "completed",
                    isMaster: false,
                    isWorker: false,
                },
            })
            .then((res) => {
                const data = res.data.data.map((server) => {
                    return {
                        id: server._id,
                        managementip: server.managementip,
                        dataip: server.dataip,
                        hostname: server.hostname,
                    };
                });
                setTableData(data);
            });
    };

    function handleAddKubernteCLuster() {
        axios.post("/api/v1/cluster/baremetal", {
            k8sClusterName: kubernetesclusterName,
            k8sId: kubernetesclusterId,
            diskPathStorage: diskPathStorage,
            elasticIP: kubernetesElasticIP,
            workerServersArray: workerSelected,
            masterServerArray: masterSelected
        }).then((res) => {
            console.log(res);
        }).catch((err) => {
            console.log(err);
            alert("Error in Creating Cluster");
            return;
        });
        setIsLoading(false)
        props.setOpen(false)
        console.log("Done");
    }

    useEffect(() => {
        getActiveServersForKuber();
    }, []);

    return (

        <div>
            <Stepper
                height="auto"
                finishCallback={handleAddKubernteCLuster}
                steps={createClusterSteps}
                currentMaxSteps={
                    (masterSelected.length === 3) +
                    (kubernetesclusterId !== "" && kubernetesclusterName !== "") +
                    (diskPathStorage != "") +
                    (kubernetesElasticIP != "") +
                    (workerSelected.length !== 0) +
                    1
                }
                setOpen={props.setOpen}
            />
        </div>
    );
};

export default AddCluster;
