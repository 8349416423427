import StyledButton from "../../../Components/Element/Button/Button";
import React, { useEffect, useState } from "react";
import "./ViewMore.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Card, Divider } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "white",
  p: 0,
};
const ViewMore = (props) => {
  const data = props.tableData[props.rowIndex];
  const location = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [Environment, setEnvironment] = useState("");
  const [CloudProvider, setCloudProvider] = useState("");



  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSubmitRedirectEKS = () => {
    console.log(data);
    location("/launcher/eks/create", {
      state: {
        requestId: data._id,
        proposalId: data.proposalId,
        subscriptionId: data.subscriptionId,
      },
    });
    props.toggleOpenState();
  };
  const handleSubmitRedirectAKS = () => {
    console.log(data);
    location("/launcher/aks/create", {
      state: {
        requestId: data._id,
        proposalId: data.proposalId,
        subscriptionId: data.subscriptionId,
      },
    });
    props.toggleOpenState();
  };
  return (

    <div className="csv-main">
      <div className="csv-headers">
        <div className="csv-title">Request Details</div>
        <div className="csv-closeX" onClick={props.toggleOpenState}>
          X
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            padding={2}
          >
            Create a New Cluster{" "}
          </Typography>
          <Divider />
          <Box
            padding={2}
            style={{
              display: "flex",
              gap: "20px",
            }}
          >
            <Box>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Select Environment
              </Typography>
              <Typography variant="caption">
                Select The Operating Environment for your cluster
              </Typography>
            </Box>
            <Card
              onClick={() => setEnvironment("Public")}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor: Environment === "Public" ? "#881630" : "white",
                color: Environment === "Public" ? "white" : "black",
              }}
            >
              <Typography fullWidth padding={4}>
                Public
              </Typography>
            </Card>
            <Card
              onClick={() => setEnvironment("Private")}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor: Environment === "Private" ? "#881630" : "white",
                color: Environment === "Private" ? "white" : "black",
              }}
            >
              <Typography fullWidth padding={4}>
                Private
              </Typography>
            </Card>
          </Box>
          <Divider />
          {Environment == "Public" && (
            <Box
              padding={2}
              style={{
                display: "flex",
                gap: "20px",
              }}
            >
              <Box>
                <Typography id="modal-modal-description" sx={{}}>
                  Select Cloud Provider
                </Typography>
                <Typography variant="caption">
                  Select The Cloud Provider where you want to create
                  <br /> the cluster
                </Typography>
              </Box>
              <Card
                onClick={() => setCloudProvider("AWS")}
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    CloudProvider === "AWS" ? "#881630" : "white",
                  color: CloudProvider === "AWS" ? "white" : "black",
                  cursor: "pointer",
                }}
              >
                <Typography padding={4}>AWS</Typography>
              </Card>
              {data.requestType === 'OTF' && <Card
                onClick={() => setCloudProvider("Azure")}
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    CloudProvider === "Azure" ? "#881630" : "white",
                  color: CloudProvider === "Azure" ? "white" : "black",
                  cursor: "pointer",
                }}
              >
                <Typography padding={4}>Azure</Typography>
              </Card>}
            </Box>
          )}
          {Environment == "Private" && (
            <Box
              padding={2}
              style={{
                display: "flex",
                gap: "20px",
              }}
            >
              <Box>
                <Typography id="modal-modal-description" sx={{}}>
                  Select Cloud Provider
                </Typography>
                <Typography variant="caption">
                  Select The Cloud Provider where you want to create
                  <br /> the cluster
                </Typography>
              </Box>
              {/* <Card
                onClick={() => setCloudProvider("AWS")}
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    CloudProvider === "AWS" ? "#881630" : "white",
                  color: CloudProvider === "AWS" ? "white" : "black",
                  cursor: "pointer",
                }}
              >
                <Typography padding={4}>AWS</Typography>
              </Card>
              {data.requestType === 'OTF' && <Card
                onClick={() => setCloudProvider("Azure")}
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    CloudProvider === "Azure" ? "#881630" : "white",
                  color: CloudProvider === "Azure" ? "white" : "black",
                  cursor: "pointer",
                }}
              >
                <Typography padding={4}>Azure</Typography>
              </Card>} */}
              {data.requestType === 'OTF' && <Card
                onClick={() => {
                  setCloudProvider("Baremetal");

                  location("/launcher/baremetal")
                }
                }
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    CloudProvider === "Baremetal" ? "#881630" : "white",
                  color: CloudProvider === "Baremetal" ? "white" : "black",
                  cursor: "pointer",
                }}
              >
                <Typography padding={4}>Baremetal</Typography>
              </Card>}
            </Box>
          )}
          <Divider />
          {CloudProvider == "AWS" && (
            <Box
              padding={2}
              style={{
                display: "flex",
                gap: "20px",
              }}
            >
              <Box>
                <Typography id="modal-modal-description" sx={{}}>
                  Select Cloud Provider
                </Typography>
                <Typography variant="caption">
                  Select The Cloud Provider where you want to create the cluster
                </Typography>
              </Box>
              <Card
                onClick={() => handleSubmitRedirectEKS()}
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    CloudProvider === "AWS" ? "#881630" : "white",
                  color: CloudProvider === "AWS" ? "white" : "black",
                  cursor: "pointer",
                }}
              >
                <Typography padding={4}>Amazon EKS</Typography>
              </Card>
            </Box>
          )}
          {CloudProvider == "Azure" && (
            <Box
              padding={2}
              style={{
                display: "flex",
                gap: "20px",
              }}
            >
              <Box>
                <Typography id="modal-modal-description" sx={{}}>
                  Select Cloud Provider
                </Typography>
                <Typography variant="caption">
                  Select The Cloud Provider where you want to create the cluster
                </Typography>
              </Box>
              <Card
                onClick={() => handleSubmitRedirectAKS()}
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    CloudProvider === "Azure" ? "#881630" : "white",
                  color: CloudProvider === "Azure" ? "white" : "black",
                  cursor: "pointer",
                }}
              >
                <Typography padding={4}>Azure AKS</Typography>
              </Card>
            </Box>
          )}
        </Box>
      </Modal>
      <div className="csv-box csv-table">
        <div className="csv-table-cover">
          <div className="server-item">
            <label className="server-item-name">Catalogoue</label>
            <p className="server-item-details">{data.catalogue}</p>
          </div>
          <div className="server-item">
            <label className="server-item-name">Location</label>
            <p className="server-item-details">{data.location}</p>
          </div>
          <h4>Client Details</h4>
          <div className="server-item">
            <label className="server-item-name">Name</label>
            <p className="server-item-details">
              {data.proposalId.endCustomerDetails.user.name}
            </p>
          </div>

          <div className="server-item">
            <label className="server-item-name">Organization Name</label>
            <p className="server-item-details">
              {data.proposalId.endCustomerDetails.organization.name}
            </p>
          </div>

          <div className="server-item">
            <label className="server-item-name">Email</label>
            <p className="server-item-details">
              {data.proposalId.endCustomerDetails.user.email}
            </p>
          </div>

          <div className="server-item">
            <label className="server-item-name">
              Credential Intent to Share
            </label>
            <p className="server-item-details">
              {data.credIntent ? "Yes" : "No"}
            </p>
          </div>
        </div>
      </div>
      <div className="csv-box csv-mid">
        <div className="csv-button-top">
          <StyledButton
            styleDetails={{
              padding: "9px 15px",
              borderRadius: "10px",
              bgcolor: "white",
              bghover: "red",
              colorhover: "white",
              color: "red",
              border: "2px solid red",
            }}
            name={"Cancel"}
            onClickFunc={props.toggleOpenState}
          />

          <StyledButton
            styleDetails={{
              padding: "9px 15px",
              borderRadius: "10px",
              bgcolor: "white",
              bghover: "#881630",
              colorhover: "white",
              color: "#881630",
              border: "2px solid #881630",
            }}
            name={"Complete Request"}
            onClickFunc={handleOpen}
          />
        </div>
      </div>
    </div>
  );
};
export default ViewMore;
