import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  userData: {},
  userType: "",
};
export const getUserDetails = createAsyncThunk("getUserDetails", async () => {
  const response = await axios.get("/api/v1/user/me");
  return response.data.data;
});
export const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    storeUserData: (state, action) => {
      state.userData = action.payload;
      state.userType = action.payload.data.data.type;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      console.log(action);
      state.userData = action.payload;
      state.userType = action.payload.type;
    });
  },
});

// Action creators are generated for each case reducer function
export const { storeUserData } = userSlice.actions;

export default userSlice.reducer;
