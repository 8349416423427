import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CachedOutlined, Add } from "@mui/icons-material";

import Searchbar from "../../Components/Element/Searchbar/Searchbar";
import BlockBox from "../../Components/Element/Box/Box";
import StyledButton from "../../Components/Element/Button/Button";
import "./EKS.css";
import CardLayout from "../../Components/Element/CardLayout/CardLayout";
import SnackbarComponent from "../../Components/Element/Snackbar/Snackbar";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Card, LinearProgress, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#881630",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const EKS = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    message: "",
    severity: "success",
  });
  const [loading, setLoading] = useState(false);
  const [clusterData, setClusterData] = useState([]);

  const navigate = useNavigate();
  const handleCreateCluster = () => {
    navigate("create");
  };

  const getClusterData = async () => {
    setLoading(true);
    axios.get("/api/v1/cluster/eks").then((res) => {
      setTimeout(() => {
        setLoading(false);
      }, 500);
      const data = res.data.data.map((item) => {
        if (!item.isDeleted) {
          return {
            CompanyName: item.CompanyName || item.cluster_name,
            endpoint: item?.cluster_endpoint || "Not Available",
            dnsHost: item?.DNSHostname || "Not Available",
            region: item?.region || "Not Available",
            clusterId: item.clusterIdentifier || "Not Available",
            status: item.status,
            cluster_endpoint: item.DNSHostname || "Not Available",
            cluster_security_group_id:
              item.cluster_security_group_id || "Not Available",
            DNS: item.DNS || "Not Available",
            Endpoint_service_name: item?.Endpoint_service_name,
            _id: item._id,
          };
        }
      });
      console.log(data);
      setSnackbarData({
        message: "Data Fetched Successfully",
        severity: "success",
      });
      setSnackbarOpen(true);
      setClusterData(data);
      console.log(data);
    });
  };
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    if (token) {
      getClusterData();
    }
  }, []);

  return (
    <div>
      <SnackbarComponent
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarData.message}
        severity={snackbarData.severity}
      />
      <div className="server-main">
        <div className="server-box">
          <BlockBox>
            <div className="server-block-header">
              <div className="server-box-title">All Clusters</div>
              <div className="server-block-header-right">
                <div style={{ marginRight: "15px" }}>
                  <Searchbar />
                </div>
                <StyledButton
                  styleDetails={{
                    padding: "9px 15px",
                    borderRadius: "10px",
                    bgcolor: "#881630",
                    bghover: "#ffc000",
                    margin: "0 5px",
                  }}
                  name={"Refresh"}
                  endIcon={<CachedOutlined />}
                  onClickFunc={getClusterData}
                />
                {/* <StyledButton
                  styleDetails={{
                    padding: "9px 15px",
                    borderRadius: "10px",
                    bgcolor: "#881630",
                    bghover: "#ffc000",
                    margin: "0px 5px",
                  }}
                  name={"Create Cluster"}
                  endIcon={<Add />}
                  onClickFunc={handleCreateCluster}
                /> */}
              </div>
            </div>
            <div className="server-table-cover">
              {loading && (
                <LinearProgress
                  color="primary"
                  sx={{
                    marginBottom: "10px",
                  }}
                />
              )}
              <div className="cluster-cards-cover">
                {clusterData.length > 0 ? (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">
                            Cluster Id
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Company Name
                          </StyledTableCell>
                          <StyledTableCell align="left">Region</StyledTableCell>
                          <StyledTableCell align="left">Status</StyledTableCell>
                          <StyledTableCell align="left">DNS</StyledTableCell>
                          <StyledTableCell align="left">
                            {/* VPC Endpoint Service Name */}
                          </StyledTableCell>
                          <StyledTableCell align="left"></StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {clusterData.map((row) => (
                          <StyledTableRow key={row?.name}>
                            <StyledTableCell align="left">
                              {row?.clusterId}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.CompanyName}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.region}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.status === "true" ? "Active" : "Initiated"}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.DNS}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.Endpoint_service_name}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <Button
                                variant="contained"
                                sx={{
                                  bgcolor: "#881630",
                                  ":hover": {
                                    bgcolor: "#ffc000",
                                  },
                                }}
                                onClick={() => {
                                  axios
                                    .delete(`/api/v1/cluster/eks/${row._id}`)
                                    .then((res) => {
                                      console.log(res.data);
                                      getClusterData();
                                    });
                                }}
                              >
                                Delete
                              </Button>{" "}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  !loading && (
                    <Card>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "20px",
                        }}
                      >
                        <Typography variant="h5">
                          No Clusters Available
                        </Typography>
                        <Typography variant="p">
                          To Create a cluster Complete the request from Requests
                          Tab in the side bar
                        </Typography>
                      </div>
                    </Card>
                  )
                )}
              </div>
            </div>
          </BlockBox>
        </div>
      </div>
    </div>
  );
};

export default EKS;
