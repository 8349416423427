import axios from "axios";

export function handleLogin(email, password, navigation, setSnackbarOpen, setSnackbarData,Login) {
    axios.post("/api/v1/auth/login", {
        email, password
    }).then((d) => {
        console.log(d.data.token)
        setSnackbarOpen(true);
        setSnackbarData({
            message: "Login Successful",
            severity: "success"
        })
        Login(d.data.token)
        navigation("/launcher/eks");
    }).catch(e => {
        setSnackbarOpen(true);
        setSnackbarData({
            message: `Please enter correct username and password, err ${e.message}`,
            severity: "error"
        })
        setTimeout(() => {
            setSnackbarOpen(false);
        }, 3000);

        console.log(e)
    })
}


export function handleSignup(data, navigation, setSnackbarOpen, setSnackbarData) {
    console.log(data)
    axios.post("/api/v1/auth/register", { data }).then((d) => {
        // console.log(d.data.token)
        // localStorage.setItem("login_token", d.data.token)
        setSnackbarOpen(true);
        setSnackbarData({
            message: d.data.data,
            severity: "success"
        })
        // navigation("/launcher/eks");
    }).catch(e => {
        setSnackbarOpen(true);
        setSnackbarData({
            message: `Please enter correct username and password, err ${e.message}`,
            severity: "error"
        })
        setTimeout(() => {
            setSnackbarOpen(false);
        }, 3000);

        console.log(e)
    })
}


export function handleMarketPlaceSignup(data, navigation, setSnackbarOpen, setSnackbarData) {
    console.log(data)
    axios.post("/api/v1/auth/register/marketplace", { data }).then((d) => {
        // console.log(d.data.data)
        // localStorage.setItem("login_token", d.data.token)
        setSnackbarOpen(true);
        setSnackbarData({
            message: d.data.data,
            severity: "success"
        })
        // navigation("/launcher/marketplace-requests");
    }).catch(e => {
        setSnackbarOpen(true);
        setSnackbarData({
            message: `Please enter correct username and password, err ${e.message}`,
            severity: "error"
        })
        setTimeout(() => {
            setSnackbarOpen(false);
        }, 3000);
        navigation("/marketplace-signup")

        console.log(e)
    })
}

export function handleVerifyEmail(email, setSnackbarOpen, setSnackbarData) {
    console.log(email);
    axios.post("/api/v1/auth/forgotPassword", { email }).then((response) => {
        if (response.status === 200) {
            setSnackbarOpen(true);
            setSnackbarData({
                message: "Verification link has been sent to your email. Please check your email.",
                severity: "success"
            });
        }
    }).catch(error => {
        setSnackbarOpen(true);
        setSnackbarData({
            message: `Please enter correct email, err ${error.message}`,
            severity: "error"
        });
        // setTimeout(() => {
        //     setSnackbarOpen(false);
        // }, 3000);

        // console.log(error);
    });
}