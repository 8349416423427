import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function ProtectedLinks({ component: Component }) {
  const navigation = useNavigate();
  const loginState = useSelector((state) => state.auth.token);
  useEffect(() => {
    if (!loginState) navigation("/");
  });
  return <Component />;
}

export default ProtectedLinks;
