import {CircularProgress} from "@mui/material";
import React from "react";
import "./Table.css";
import {
    handleCheckBoxChange,
    handleInputChange,
    handleSelectAllClick,
} from "./TableFunction";
import DeleteIcon from '@mui/icons-material/Delete';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {ViewList, VisibilityOffOutlined, VisibilityOutlined} from "@mui/icons-material";

const Table = (props) => {
    return (
        <table className="table-main" style={{maxHeight: props.maxHeight}}>
            <thead>
            <tr>
                {props.column &&
                    props.columns.map((val) => (
                        <>
                            <th style={{width: val.wid}}>
                                {val.name === "CheckAll" ? (
                                    <input
                                        type={"checkbox"}
                                        checked={props.checkboxData.length === props.rows.length}
                                        onChange={(e) =>
                                            handleSelectAllClick(e, props.rows, props.setCheckBoxData)
                                        }
                                        className="table-input"
                                    />
                                ) : (
                                    val.name
                                )}
                            </th>
                        </>
                    ))}
            </tr>
            </thead>
            <tbody className="tableBody" style={{maxHeight: props.maxHeight}}>
            {props.rows.length === 0 && (
                <tr>
                    <td  className="td-text" colSpan={props.columns.length}>
                        <div>{props.loading ? (
                            <CircularProgress/>
                        ) : props.customTableMessage ? (
                            props.customTableMessage
                        ) : (
                            "No Data Found"
                        )}</div>
                    </td>
                </tr>
            )}
            {props.rows.length !== 0 && props.loading ? (
                <td className="td-text" colSpan={props.columns.length}>
                    {" "}
                    <CircularProgress/>{" "}
                </td>
            ) : (
                props.rows.map((row, idx) => (
                    <tr>
                        {props.columns.map((val) => (
                            <>
                                {val.type === "status" && (
                                    <td>
                                        <div className="td-status-text">
                                            {
                                                row[val.key] ?
                                                    <p className={`table-status table-status-success`}>Active</p>
                                                    :
                                                    <p className={`table-status table-status-danger`}>Inactive</p>
                                            }
                                        </div>
                                    </td>
                                )}
                                {val.type === "os" && (
                                    <td>
                                        <div className="td-os-text">
                                            <p className={`table-status table-status-${row[val.key] === 'inactive' ? 'danger' : row[val.key] === 'completed' ? 'success' : 'warning'}`}>{row[val.key]}</p>
                                        </div>
                                    </td>
                                )}
                                {val.type === "health" && (
                                    <td>
                                        <div className="td-health-text">
                                            {row.healthCheck && (
                                                <p className={`table-status table-status-success`}>
                                                    Healthy
                                                </p>
                                            )}
                                            {!row.healthCheck && (
                                                <p className={`table-status table-status-danger`}>
                                                    Unhealthy
                                                </p>
                                            )}
                                        </div>

                                    </td>
                                )}
                                {val.type === "label" && <td>
                                    <div>{row[val.key]}</div>
                                </td>}
                                {val.type === "action" && (
                                    <td>
                                        <div className="table-action-btn">
                                            {props.handleDelete && <DeleteIcon onClick={() => props.handleDelete(idx)}
                                                                               className="table-action-delete-btn"/>}
                                            {props.showDetails &&
                                                <VisibilityOutlined onClick={() => props.showDetails(idx)}
                                                                  className="table-action-show-btn"/>}

                                        </div>
                                    </td>
                                )}
                                {val.editable === true && (
                                    <td>
                                        {val.type !== "checkbox" && (
                                            <input
                                                type={val.type}
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        props.setTableServerData,
                                                        props.rows,
                                                        e,
                                                        idx,
                                                        val.key
                                                    )
                                                }
                                                className="editable-input"
                                                placeholder={val.placeholder}
                                                value={row[val.key]}
                                            />
                                        )}

                                        {val.type === "checkbox" && (
                                            <input
                                                type={"checkbox"}
                                                checked={
                                                    props.checkboxData.indexOf(row[val.key]) !== -1
                                                }
                                                onChange={() =>
                                                    handleCheckBoxChange(
                                                        props.setCheckBoxData,
                                                        props.checkboxData,
                                                        row[val.key],
                                                        props.maxCheck
                                                    )
                                                }
                                                className="checkbox-input"
                                            />
                                        )}
                                    </td>
                                )}
                            </>
                        ))}
                    </tr>
                ))
            )}
            </tbody>
        </table>
    );
};

export default Table;