import React from "react";
import { Paper, Typography } from "@mui/material";
import { styled } from "@mui/system";
import "./TileLayout.css";
import { Link } from "react-router-dom";


const StyledPaper = styled(Paper)(({ theme, ...props }) => ({
    backgroundColor: `${props.styleDetails?.bgcolor || "white"}`,
    border: "1px solid #f0f0f0", // Set a border color
    borderRadius: "10px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.2s, transform 0.2s, box-shadow 0.2s",
    cursor: "pointer",
    boxShadow: "0 0 8px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
    "&:hover": {
      backgroundColor: "#f9f9f9", // Lighten the background color on hover
      transform: "scale(1.02)",
    },
    ...(props.styleDetails || {}),
  }));
  
  
const tileTextStyles = {
  color: "#ffc000", // Text color
};

const iconStyles = {
  fontSize: "2rem", // You can adjust the size as needed
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const TileLayout = (props) => {
  return (
    
    <StyledPaper onClick={props.onClickFunc} styleDetails={props.styleDetails}>
      <div className="tile-icon" style={iconStyles}>
        {props.icon}
      </div>
      <Typography variant="h6" className="tile-name" sx={tileTextStyles}>
        {props.name}
      </Typography>
    </StyledPaper>
  );
};

export default TileLayout;
