import React, {useEffect, useState} from "react";
import "./AddWorkerToCluster.css";
import axios from "axios";
import Stepper from "../../../Components/Element/Stepper/Stepper";
import SelectCluster from "./Steps/SelectCluster/SelectCluster";
import WorkerServers from "./Steps/WorkerServers/WorkerServers";

const AddWorkerToCluster = (props) => {
    const [clusterTableData, setClusterTableData] = useState([]);
    const [workerTableData, setWorkerTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [workerSelected, setWorkerSelected] = useState([]);
    const [clusterSelected, setClusterSelected] = useState([]);

    const addWorkerServers = [
        {
            label: "Select Cluster to Add Worker Servers",
            content: (
                <SelectCluster
                    setCheckBoxSelected={setClusterSelected}
                    checkBoxSelected={clusterSelected}
                    tableData={clusterTableData}
                />
            ),
        },
        {
            label: "Select Worker Servers",
            content: (
                <WorkerServers
                    setCheckBoxSelected={setWorkerSelected}
                    checkBoxSelected={workerSelected}
                    tableData={workerTableData}
                />
            ),
        }
    ];
    const getActiveServersForWorkerAdd = () => {
        axios
            .get("/api/v1/server", {
                params: {
                    isActive: true,
                    osInstallationStatus: "completed",
                    isMaster: false,
                    isWorker: false,
                },
            })
            .then((res) => {
                const data =res.data.data && res.data.data.map((server) => {
                    return {
                        id: server._id,
                        managementip: server.managementip,
                        dataip: server.dataip,
                        hostname: server.hostname,
                    };
                });
                setWorkerTableData(data);
            });
    };
    const getActiveClusterForWorkerAdd = () => {
        axios
            .get("/api/v1/cluster", {
                params: {
                    status: true,
                },
            })
            .then((res) => {
                const data = res.data.data.map((cluster) => {
                    return {
                        id: cluster._id,
                        k8sId: (cluster._id).slice(-8),
                        k8sClusterName: cluster.orgName,
                    };
                });
                setClusterTableData(data);
            });
    };

    function handleWorkerAddition() {
        axios.post("/api/v1/cluster/addWorker", {
            clusterID: clusterSelected,
            workerIPs: workerSelected,
        }).then((res) => {
            console.log(res);
            props.setSnackbarOpen(true);
            props.setSnackbarData({message: "Worker Added to Cluster Successfully", severity: "success"});
        }).catch((err) => {
            console.log(err);
            props.setSnackbarOpen(true);
            props.setSnackbarData({message: "Error in adding worker to cluster", severity: "error"});
            return;
        });
        setIsLoading(false)
        props.setOpen(false)
        console.log("Done");
    }

    useEffect(() => {
        getActiveClusterForWorkerAdd();
        getActiveServersForWorkerAdd()
    }, []);

    return (
        <Stepper
            finishCallback={handleWorkerAddition}
            steps={addWorkerServers}
            currentMaxSteps={
                (clusterSelected.length === 1) +
                (workerSelected.length !== 0) +
                1
            }
            setOpen={props.setOpen}
        />
    );
};

export default AddWorkerToCluster;
