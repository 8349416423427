export const LayoutBarOptions = [
  { name: "EKS", link: "eks" },
  { name: "AKS", link: "aks" },
  { name: "Baremetal", link: "baremetal" },
  { name: "Request", link: "request" },
  { name: "Logout", link: "logout" }
];

export const MarketPlaceLayoutBarOptions = [
  { name: "EKS", link: "eks" },
  { name: "Marketplace-Users", link: "marketplace-users" },
  { name: "Marketplace-Requests", link: "marketplace-requests" },
  { name: "Logout", link: "logout" }
]