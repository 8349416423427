import React from "react";
import Input from "../../../../../Components/Element/Input/Input";
import "./ClusterDetails.css";

const ClusterDetails = (props) => {
    return (
        <div className="clusterDetails-step-main">
            <div className="clusterdetails-input">
                <Input
                    styled={{
                        padding: "10px 5px",
                        borderRadius: "5px",
                        margin: "10px 0 0 0",
                    }}
                    name={"Baremetal Clusters Id"}
                    type={"text"}
                    placeholder={"Baremetal ClustersId"}
                    callback={props.setKubernetesClusterId}
                />
            </div>
            <div className="step-input">
                <Input
                    styled={{
                        padding: "10px 5px",
                        borderRadius: "5px",
                        margin: "10px 0 0 0",
                    }}
                    name={"Baremetal Cluster Name"}
                    type={"text"}
                    placeholder={"Baremetal ClustersName"}
                    callback={props.setKubernetesClusterName}
                />
            </div>
        </div>
    );
};

export default ClusterDetails;
