import React, {useEffect, useState} from "react";
import StyledButton from "../../Components/Element/Button/Button";
import BlockBox from "../../Components/Element/Box/Box";
import Searchbar from "../../Components/Element/Searchbar/Searchbar";
import "./AddEdge.css";
import AddIcon from "@mui/icons-material/Add";
import Table from "../../Components/Element/Table/Table";
import SpringModal from "../../Components/Element/Modal/Modal";
import Csvtable from "./CsvTable/Csvtable";
import axios from "axios";
import {Add, CachedOutlined} from "@mui/icons-material";
import SnackbarComponent from "../../Components/Element/Snackbar/Snackbar";
import AddWorkerToCluster from "./AddWorkerToCluster/AddWorkerToCluster";
import OSTable from "./OSTable/OSTable";
import ViewMore from './ViewMore/ViewMore'

const tableHeaders = [
    {name: "Management IP", type: "label", key: "managementip"},
    // {name: "Data Interface", type: "label", key: "dataInterface"},
    {name: "Hostname", type: "label", key: "hostname"},
    // {name: "OS Status", type: "os", key: "osInstallationStatus"},
    {name: "Status", type: "status", key: "status"},
    {name: "View", type: "action", key: "action"},
];

const Servers = () => {
    const [tableData, setTableData] = useState([]);
    const [clusterData, setClusterData] = useState([]);
    const [openCSVModel, setOpenCSVModel] = useState(false);
    const [openOSModel, setOpenOSModel] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [rowIndex, setRowIndex] = useState({});
    const [openRow, setOpenRow] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarData, setSnackbarData] = useState({message: "", severity: "success"})
    const [openAddWorkerModel, setOpenAddWorkerModel] = useState(false);


    const handleAddWorkerToggle = () => {
        setOpenAddWorkerModel(!openAddWorkerModel);
        getClustersData();
    };

    const handleOSToggle = () => {
        setOpenOSModel(!openOSModel);
    }

    const getClustersData = () => {
        setIsLoading(true);
        axios.get("/api/v1/cluster/eks").then((res) => {
            const data = res.data.data;
            setClusterData(data);
            setIsLoading(false);
        })
    }


    const getServerData = async () => {
        setIsLoading(true);
        axios.get("/api/v1/server").then((res) => {
            const data = res.data.data.map((item) => {
                return {
                    ...item,
                    status: item.isActive,
                };
            });
            setTableData(data);
            setIsLoading(false);
        })
    };

    const refreshTable = () => {
        setIsLoading(true);
        axios.get("/api/v1/server").then((res) => {
            axios.post("/api/v1/server/status/", {servers: res.data.data})
                .then((k) => {
                    const data = k.data.data.map((item) => {
                        return {
                            ...item,
                            status: item.isActive,
                        };
                    });
                    setTableData(data);
                    setIsLoading(false);
                });
        });
    }

    const deleteServer = async (id) => {
        // axios.delete(`/api/v1/server/${id}`).then((res) => {
        setSnackbarData({message: "Server Cant Be Deleted", severity: "error"})
        setSnackbarOpen(true)
        // getServerData()
        // })
    }

    const handleCSVToggle = () => {
        setOpenCSVModel(!openCSVModel);
        getServerData()
        refreshTable()
    }

    const handleViewToggle = () => {
        setOpenRow(!openRow)
    }

    const showDetails = (e) => {
        handleViewToggle()
        setRowIndex(e)
    }


    useEffect(() => {
        getServerData();
        getClustersData()
    }, []);
    return (
        <>
            <SnackbarComponent open={snackbarOpen} setOpen={setSnackbarOpen} message={snackbarData.message}
                               severity={snackbarData.severity}/>
            <SpringModal styleModal={{width: "80vw", lgWidth: "100vw", mdWidth: "97vw"}} open={openCSVModel}
                         setOpen={setOpenCSVModel}>
                <Csvtable toggleOpenState={handleCSVToggle} setSnackbarOpen={setSnackbarOpen}
                          setSnackbarData={setSnackbarData}/>
            </SpringModal>
            <SpringModal open={openAddWorkerModel}>
                <AddWorkerToCluster setOpen={setOpenAddWorkerModel} setSnackbarOpen={setSnackbarOpen} setSnackbarData={setSnackbarData}/>
            </SpringModal>
            <SpringModal styleModal={{width: "80vw", lgWidth: "80vw", mdWidth: "97vw"}} open={openOSModel}
                         setOpen={setOpenOSModel}>
                <OSTable toggleOpenState={handleOSToggle} setSnackbarOpen={setSnackbarOpen}
                         setSnackbarData={setSnackbarData}/>
            </SpringModal>
            <SpringModal styleModal={{width: "80vw", lgWidth: "80vw", mdWidth: "97vw"}}
                         open={openRow} setOpen={setOpenRow}>
                <ViewMore tableData={tableData} deleteServer={deleteServer} rowIndex={rowIndex}
                          toggleOpenState={handleViewToggle}/>
            </SpringModal>
            <div className="server-main">
                <div className="server-box">
                    <BlockBox>
                        <div className="server-block-header">
                            <div className="server-box-title">All Servers</div>
                            <div className="server-block-header-right">
                                <div style={{marginRight: "15px"}}>
                                    <Searchbar/>
                                </div>
                                <StyledButton
                                    styleDetails={{
                                        padding: "9px 15px",
                                        borderRadius: "10px",
                                        bgcolor: "#881630",
                                        bghover: "#ffc000",
                                        margin: "0 5px"
                                    }}
                                    name={"Refresh"}
                                    endIcon={<CachedOutlined/>}
                                    onClickFunc={refreshTable}
                                />
                                <StyledButton
                                    styleDetails={{
                                        padding: "9px 15px",
                                        borderRadius: "10px",
                                        bgcolor: "#881630",
                                        bghover: "#ffc000",
                                        margin: "0px 5px"
                                    }}
                                    name={"Create New Servers"}
                                    endIcon={<AddIcon/>}
                                    onClickFunc={handleCSVToggle}
                                />
                                 {/* <StyledButton
                                    styleDetails={{
                                        padding: "9px 15px",
                                        borderRadius: "10px",
                                        bgcolor: "#881630",
                                        bghover: "#ffc000",
                                        margin: "0px 5px"
                                    }}
                                    name={"Add OS"}
                                    disabled={isLoading || tableData.length === 0}
                                    endIcon={<AddIcon/>}
                                    onClickFunc={handleOSToggle}
                                /> */}
                                {/* <StyledButton
                                    styleDetails={{
                                        padding: "9px 15px",
                                        borderRadius: "10px",
                                        bgcolor: "#881630",
                                        bghover: "#ffc000",
                                        margin: "0px 5px"
                                    }}
                                    name={"Add Edge"}
                                    disabled={isLoading || clusterData.length === 0}
                                    endIcon={<AddIcon/>}
                                    onClickFunc={handleAddWorkerToggle}
                                />  */}
                            </div>
                        </div>

                        <div className="server-table-cover">
                            <Table columns={tableHeaders} showDetails={showDetails} loading={isLoading} column={true} rows={tableData}/>
                        </div>
                    </BlockBox>
                </div>
            </div>
        </>
    );
};

export default Servers;
