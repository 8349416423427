import React from "react";
// import './DomainName.css'
import Input from "../../../../../Components/Element/Input/Input";
import TextBox from "../../../../../Components/Element/TextBox/TextBox";
import BlockBox from "../../../../../Components/Element/Box/Box";

const DomainName = (props) => {
    return (
        <BlockBox>
            <div className="clusterDetails-step-main">
                <h5 style={{color:"red"}}>* Please add the domain name eg : abc.com from *.abc.com</h5>
                <div className="clusterdetails-input">
                    <Input
                        styled={{
                            padding: "10px 5px",
                            borderRadius: "5px",
                            margin: "10px 0 0 0",
                        }}
                        name={"Enter Domain Name"}
                        value={props.domainName}
                        placeholder={"eg: abc.com"}
                        callback={props.setDomainName}
                    />
                </div>
            </div>
            <div className="clusterDetails-step-main">
                <h3 style={{fontWeight:400}}>Upload Certificate : </h3>
                <div className="clusterdetails-input">
                    <TextBox
                        styled={{
                            padding: "10px 5px",
                            borderRadius: "5px",
                            margin: "10px 0 0 0",
                        }}
                        name={"Enter Bundled cert file:"}
                        type={"text"}
                        placeholder={"Enter Bundled cert file"}
                        callback={props.setCertFile}
                    />
                </div>
                <div className="step-input">
                    <TextBox
                        styled={{
                            padding: "10px 5px",
                            borderRadius: "5px",
                            margin: "10px 0 0 0",
                        }}
                        name={"Enter Key File:"}
                        type={"text"}
                        placeholder={"Enter Key File"}
                        callback={props.setKeyFile}
                    />
                </div>
            </div>
    </BlockBox>

)
}

export default DomainName
