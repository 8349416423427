import React, {useEffect, useState} from "react";
import axios from "axios";
import Table from "../../../../../Components/Element/Table/Table";
import "./MasterServer.css"

const tableHeaders = [
    {name: "", type: "checkbox", editable: true, key: "id", wid: 10},
    {name: "Data IP", type: "label", key: "dataip"},
    {name: "Management IP", type: "label", key: "managementip"},
    {name: "Hostname", type: "label", key: "hostname"},
    // {name: "Password", type: "label", key: "password"},
];

 
const MasterServers = (props) => {
    const [isLoading, setIsLoading] = useState(false);


    return (
        <div className="MasterServer-step-main">
            <h5 style={{color: "red"}}>* You can select 3 Master Servers only</h5>
            <Table
                columns={tableHeaders}
                loading={isLoading}
                column={true}
                rows={props.tableData}
                customTableMessage={"No Compatible Server Available"}
                setCheckBoxData={props.setCheckBoxSelected}
                checkboxData={props.checkBoxSelected}
                maxCheck={3}
            />
        </div>
    );
};

export default MasterServers;
