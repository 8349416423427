import React, {useEffect, useState} from "react";
import Table from "../../../../../Components/Element/Table/Table";

const tableHeaders = [
    {name: "", type: "checkbox", editable: true, key: "id", wid: 10},
    {name: "K8 ID", type: "label", key: "k8sId"},
    {name: "K8 Name", type: "label", key: "k8sClusterName"},
];
const SelectCluster = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    return (
        <div className="MasterServer-step-main">
            <Table
                columns={tableHeaders}
                loading={isLoading}
                column={true}
                rows={props.tableData}
                customTableMessage={"No Compatible Cluster Available"}
                setCheckBoxData={props.setCheckBoxSelected}
                checkboxData={props.checkBoxSelected}
            />
        </div>
    )
}

export default SelectCluster