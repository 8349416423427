import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import axios from "axios";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import zeblokLogo from "../../Assets/Images/logo.svg";
import toggleIcon from "../../Assets/Images/toggle.svg";
import zeblokIcon from "../../Assets/Images/zebloklogo.png";
import {
  Image,
  Language,
  Logout,
  RequestPage,
  ShoppingCart,
  RequestQuoteTwoTone,
  Add,
  Security,
  Workspaces,
  CloudCircle,
  Computer,
} from "@mui/icons-material";
import { Outlet, useNavigate } from "react-router-dom";
import {
  LayoutBarOptions,
  MarketPlaceLayoutBarOptions,
} from "./LayoutConstants";
import { useLocation } from "react-router-dom";
import { AppBar, Drawer, DrawerHeader, StyledToolbar } from "./LayoutUtils";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { SettingsSystemDaydream } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import SupportButton from "./Support";
import { getUserDetails } from "../../slice/userSlice";
import { useDispatch, useSelector } from "react-redux";

const drawerWidth = 240;

export default function MainLayout() {
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const userType = useSelector((state) => state.user.userType);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (userType === "") {
      dispatch(getUserDetails());
    }
  }, []);

  const navigation = useNavigate();
  const handleDrawer = () => {
    setOpen(!open);
  };

  // State for the menu anchor
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  // Function to handle menu open
  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  // Function to handle menu close
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleInfoClick = () => {
    // Navigate to the desired page when the "info" button is clicked
    navigation("/your-new-page"); // Replace "/your-new-page" with the actual path
    handleMenuClose(); // Close the menu
  };

  const toggleSupport = () => {
    // Add your logic here to toggle the support popup or perform other actions
    console.log("hi");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <StyledToolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            sx={{
              marginRight: 1,
            }}
          >
            <img src={toggleIcon} alt={"Toggle button"} />
          </IconButton>
          <Typography
            variant="h6"
            sx={{ color: "black" }}
            noWrap
            component="div"
          >
            {location.pathname === "/launcher/eks" && "EKS Dashboard"}
            {location.pathname === "/launcher/aks" && "AKS Dashboard"}
            {location.pathname === "launcher/eks/request" && "Requests"}
            {location.pathname === "/logout" && "Logout"}
            {location.pathname === "/marketplace-users" && "Marketplace-Users"}
            {location.pathname === "/marketplace-requests" &&
              "Marketplace-Requests"}
            {location.pathname === "/welcome/addedge" && "Add Edge"}
            {location.pathname === "/welcome/security" && "Security"}
            {location.pathname === "/welcome/kubernetescluster" &&
              "Kubernetes Clusters"}
          </Typography>
          <div style={{ flexGrow: 1 }}></div>{" "}
          {/* Add this to push the "info" button to the right */}
          <div style={{ flexGrow: 1 }}></div>
          <IconButton
            color="inherit"
            aria-label="info button"
            edge="end"
            onClick={handleMenuOpen} // Open the menu when clicked
            style={{
              background: "inherit", // Apply background: inherit
              padding: "0px", // Remove padding
              marginLeft: "12px", // Add margin-left
              fontSize: "28px", // Font size
              width: "30px", // Width
              height: "30px", // Height
              color: "#ffc000", // Color
            }}
          >
            <InfoIcon />
          </IconButton>
          <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose} // Close the menu when clicked outside
          >
            <MenuItem>
              <InfoIcon fontSize="small" onClick={handleInfoClick} /> About
              Basic Launcher
            </MenuItem>
          </Menu>
        </StyledToolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          {open ? (
            <img src={zeblokLogo} alt={"Zeblok Logo"} />
          ) : (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawer}
            >
              <img src={zeblokIcon} alt={"Toggle button"} width={"40px"} />
            </IconButton>
          )}
        </DrawerHeader>
        {userType !== "marketplace" && (
          <List>
            {LayoutBarOptions.map((text) => (
              <ListItem
                key={text.link}
                disablePadding
                onClick={() => navigation(text.link)}
                sx={{
                  display: "block",
                  background: `${
                    location.pathname === `/launcher/${text.link}`
                      ? "#E5E5E5"
                      : "white"
                  }`,
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {text.name === "EKS" && (
                      <img src="/eks.png" alt="aks" width={"30px"} />
                    )}
                    {text.name === "AKS" && (
                      <img src="/aks.png" alt="eks" width={"30px"} />
                    )}
                    {text.name === "Request" && <RequestPage />}
                    {text.name === "Logout" && <Logout />}
                    {text.name === "Add Edge" && <Add />}
                    {text.name === "Baremetal Clusters" && <Workspaces />}
                    {text.name === "Security" && <Security />}
                    {text.name === "Ai-Microcloud" && <CloudCircle />}
                    {text.name === "Baremetal" && <Computer />}
                  </ListItemIcon>
                  <ListItemText
                    primary={text.name}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}

        {userType === "marketplace" && (
          <List>
            {MarketPlaceLayoutBarOptions.map((text) => (
              <ListItem
                key={text.link}
                disablePadding
                onClick={() => navigation(text.link)}
                sx={{
                  display: "block",
                  background: `${
                    location.pathname === `/launcher/${text.link}`
                      ? "#E5E5E5"
                      : "white"
                  }`,
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {text.name === "EKS" && (
                      <img src="/eks.png" alt="aks" width={"30px"} />
                    )}
                    {text.name === "Logout" && <Logout />}
                    {text.name === "Marketplace-Users" && <ShoppingCart />}
                    {text.name === "Marketplace-Requests" && (
                      <RequestQuoteTwoTone />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={text.name}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: open ? `calc(100vw - ${drawerWidth}px)` : "95vw",
          overflow: "scroll",
          WebkitOverflowScrolling: "none",
        }}
      >
        <DrawerHeader />
        <Outlet />
        {/* Support button */}
        <SupportButton />
      </Box>
    </Box>
  );
}
