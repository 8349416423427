import * as React from "react";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import CloseIcon from "@mui/icons-material/Close";

export default function Stepper(props) {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = props.steps.length;

    const stepperStyle = {height: props.height ? props.height : 255, p: 2, width: props.width? props.width :"fit-content", overflow: "scroll"}

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Box sx={{flexGrow: 1}} open={false}>
            <Paper
                square
                elevation={0}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: 50,
                    pl: 2,
                    pr: 2,
                    bgcolor: "background.default",
                }}
            >
                <Typography>{props.steps[activeStep].label}</Typography>
                <CloseIcon onClick={() => props.setOpen(false)} style={{cursor: "pointer"}}/>
            </Paper>
            <Box
                style={stepperStyle}
            >
                {props.steps[activeStep].content}
            </Box>
            <MobileStepper
                variant="text"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={() =>
                            props.currentMaxSteps === maxSteps + 1
                                ? props.finishCallback()
                                : handleNext()
                        }
                        disabled={activeStep === props.currentMaxSteps - 1}
                    >
                        {props.currentMaxSteps === maxSteps + 1 ? "Finish" : "Next"}
                        {theme.direction === "rtl" ? (
                            <KeyboardArrowLeft/>
                        ) : (
                            <KeyboardArrowRight/>
                        )}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === "rtl" ? (
                            <KeyboardArrowRight/>
                        ) : (
                            <KeyboardArrowLeft/>
                        )}
                        Back
                    </Button>
                }
            />
        </Box>
    );
}
