import React, { useEffect, useState } from "react";
import StyledButton from "../../../Components/Element/Button/Button";
import Table from "../../../Components/Element/Table/Table";
import "./CsvTable.css";
import axios from "axios";

const tableHeaders = [
    // {name: "Management MAC Address", type: "text", editable: true, key: "mgMacAdd", placeholder: "Enter Management MAC Address"},
    // {name: "BMC IP", type: "ip", editable: true, key: "bmcip", placeholder: "Enter BMC IP"},
    { name: "Management IP", type: "ip", key: "managementip", editable: true, placeholder: "Enter IP" },
    // {name: "Data IP", type: "ip", key: "dataip", editable: true, placeholder: "Enter Data IP"},
    { name: "Username", type: "text", editable: true, key: "username", placeholder: "Enter Username" },
    { name: "Password", type: "password", editable: true, key: "password", placeholder: "Enter Password" },
    { name: "Hostname", type: "text", editable: true, key: "hostname", placeholder: "Enter Hostname" },
    // {name: "Data Gateway", type: "text", editable: true, key: "dataGateway", placeholder: "Enter Gateway"},
    // {name: "Data Interface", type: "text", editable: true, key: "dataInterface", placeholder: "Enter Data Interface"},
    // {name: "Management Interface", type: "text", editable: true, key: "mgInterface", placeholder: "Enter Management Interface"},
    { name: "Action", type: "action", key: "action" },
];

const Csvtable = (props) => {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const addNewRow = () => {
        const emptyRow = {
            bmcip: "",
            managementip: "",
            // dataip: "",
            hostname: "",
            // dataInterface: "",
            username: "",
            password: "",
        };
        setTableData([...tableData, emptyRow]);
    }

    const importCSV = () => {
        // downloadTemplate();
        document.getElementById("docpicker").click();
    }


    const handleCsvImport = () => {
        const fileInput = document.getElementById("docpicker");
        const file = fileInput.files[0];
        const reader = new FileReader();
        if (file) {
            reader.readAsText(file);
            reader.onload = async function () {
                const csv = reader.result;
                const lines = csv.split("\n");
                const result = [];
                const headerRow = lines[0].split(",");

                for (let i = 1; i < lines.length; i++) {
                    const row = lines[i].split(",");
                    const obj = {};

                    for (let j = 0; j < headerRow.length; j++) {
                        const key = headerRow[j].toLowerCase().replace(/[\s\r^M]/g, '');
                        const value = row[j].replace(/[\r]/g, ''); // Remove \r from the value
                        obj[key] = value;
                    }

                    result.push(obj);

                    console.log(result);
                }

                // Append new rows to existing tableData
                // setTableData([...tableData, ...result]);
                // console.log(tableData);
                result.length >= 1 && axios.post("/api/v1/server", { servers: result }).then((res) => {
                    console.log(res);
                    props.setSnackbarOpen(true)
                    props.setSnackbarData({
                        message: "Servers added Successfully",
                        severity: "success"
                    })
                }).catch((err) => {
                    props.setSnackbarOpen(true)
                    props.setSnackbarData({
                        message: err.message,
                        severity: "error"
                    })
                    console.log(err)
                    return;
                });
                props.toggleOpenState()
            };
        }
    };
    const downloadTemplate = () => {
        const csvHeader = tableHeaders.filter(header => header.name !== "Action").map((header) => header.name).join(",");
        const templateCsv = csvHeader + "\n";
        const blob = new Blob([templateCsv], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "server_template.csv";
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const checkData = () => {
        tableData.forEach((item) => {
            if (item.managementip === "" || item.hostname === "" || item.username === "") {
                setButtonDisabled(true)
            } else {
                setButtonDisabled(false)
            }
        })
    };

    const handleDelete = (idx) => {
        console.log(idx)
        const removedData = [...tableData];
        // console.log(removedData, "first")
        removedData.splice(idx, 1);
        // console.log(removedData);
        setTableData(removedData);
    }

    const sendData = () => {
        if (tableData.length === 0) return;
        tableData.forEach((item) => {
            console.log(item);
            console.log("Please fill all the fields");
            return;
        })
        setIsLoading(true)
        axios.post("/api/v1/server", { servers: tableData }).then((res) => {
            console.log(res);
            props.setSnackbarOpen(true)
            props.setSnackbarData({
                message: "Servers added Successfully",
                severity: "success"
            })
        }).catch((err) => {
            props.setSnackbarOpen(true)
            props.setSnackbarData({
                message: err.message,
                severity: "error"
            })
            console.log(err)
            return;
        });
        setIsLoading(false)
        props.toggleOpenState();
    };
    useEffect(() => {
        checkData();
    }, [tableData]);
    return (
        <div className="csv-main">
            <div className="csv-headers">
                <div className="csv-title">Create Server</div>
                <div className="csv-closeX" onClick={props.toggleOpenState}>X</div>
            </div>
            <div className="csv-box csv-end">
                <div className="csv-button-top" style={{ display: 'flex' }}>
                    <input type="file" onChange={(e) => handleCsvImport(e)} style={{ display: "none" }} id="docpicker"
                        accept="text/csv" multiple />

                    <StyledButton
                        styleDetails={{
                            padding: "9px 15px",
                            borderRadius: "10px",
                            bgcolor: "#881630",
                            bghover: "#ffc000",
                        }}
                        onClickFunc={downloadTemplate}
                        name={"Download Template"}
                    />

                    <StyledButton
                        styleDetails={{
                            padding: "9px 15px",
                            borderRadius: "10px",
                            bgcolor: "#881630",
                            bghover: "#ffc000",
                        }}
                        name={"Import Csv"}
                        onClickFunc={importCSV}
                    />

                    <StyledButton
                        styleDetails={{
                            padding: "9px 15px",
                            borderRadius: "10px",
                            bgcolor: "#881630",
                            bghover: "#ffc000",
                        }}
                        onClickFunc={addNewRow}
                        name={"Add New"}
                    />
                </div>
            </div>
            <div className="csv-box csv-table">
                <div className="csv-table-cover">
                    <Table
                        loading={isLoading}
                        tableStyle={{ fontsize: "12px" }}
                        columns={tableHeaders}
                        rows={tableData}
                        maxHeight={"10em"}
                        setTableServerData={setTableData}
                        handleDelete={handleDelete}
                    />
                </div>
            </div>
            <div className="csv-box csv-mid">
                <div className="csv-button-top" style={{ display: 'flex' }}>

                    <StyledButton
                        styleDetails={{
                            padding: "9px 15px",
                            borderRadius: "10px",
                            bgcolor: "white",
                            bghover: "#881630",
                            colorhover: "white",
                            color: "#881630",
                            border: "2px solid #881630"
                        }}
                        name={"Cancel"}
                        onClickFunc={props.toggleOpenState}
                    />
                    <StyledButton
                        styleDetails={{
                            padding: "9px 15px",
                            borderRadius: "10px",
                            bgcolor: "#881630",
                            bghover: "#ffc000",
                        }}
                        disabled={buttonDisabled}
                        onClickFunc={sendData}
                        name={"Validate"}
                    />

                </div>
            </div>
        </div>
    );
};

export default Csvtable;
