import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import Login from "./Page/Login/Login";
import Logout from "./Page/Logout/Logout";
import ProtectedRoute from "./Protected";
import MainLayout from "./Components/Layout/Layout";
import EKS from "./Page/EKS/EKS";
// import ClusterInfo from "./Page/EKS/Cluster/Cluster";
import Request from "./Page/Request/Request";
import AKS from "./Page/AKS/AKS";
import CreateAKSCluster from "./Page/AKS/CreateCluster/CreateCluster";
import EKSClusterForm from "./Page/EKS/CreateCluster/CreateCluster";
import MarketPlace from "./Page/Marketplace/Marketplace"
import MarketPlaceLogin from "./Page/Login/MarketPlaceLogin";
import MarketPlaceRequests from "./Page/Marketplace-Request/MarketPlaceRequest"
import WelcomePage from "./Page/Welcome/Welcome";
import AddEdge from "./Page/AddEdge/AddEdge"
import Security from "./Page/Security/Security";
import KubernetesCluster from "./Page/KubernetsCluster/KubernetesCluster"
import { SocketContext, socket } from "./context/socket";
import AiMicrocloud from "./Page/AiMicrocloud/AiMicrocloud"
import Baremetal from "./Page/Baremetal/baremetal";
import ResetPassword from "./Page/ResetPassword/ResetPassword";




function App() {
  return (
    <SocketContext.Provider value={socket}>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/resetPassword/:resetToken" element={<ResetPassword />} />

            <Route path="/welcome-page/:name" element={<WelcomePage />} />
            <Route
              path="marketplace-signup"
              element={<MarketPlaceLogin />}
              exact
            />

            <Route
              path="launcher"
              element={<ProtectedRoute component={MainLayout} />}
              exact
            >
              <Route
                path="logout"
                element={<ProtectedRoute component={Logout} />}
                exact
              />
              <Route
                path="eks"
                element={<ProtectedRoute component={EKS} />}
                exact
              />
              <Route
                path="aks"
                element={<ProtectedRoute component={AKS} />}
                exact
              />
              <Route
                path="eks/create"
                element={<ProtectedRoute component={EKSClusterForm} />}
                exact
              />
              <Route
                path="aks/create"
                element={<ProtectedRoute component={CreateAKSCluster} />}
                exact
              />
              {/* <Route
              path="cluster/:id"
              element={<ProtectedRoute component={ClusterInfo} />}
              exact
            /> */}
              <Route
                path="request"
                element={<ProtectedRoute component={Request} />}
                exact
              />
              <Route
                path="marketplace-users"
                element={<ProtectedRoute component={MarketPlace} />}
                exact
              />
              <Route
                path="marketplace-requests"
                element={<ProtectedRoute component={MarketPlaceRequests} />}
                exact
              />

              <Route
                path="baremetal"
                element={<ProtectedRoute component={Baremetal} />}
                exact
              />
                   <Route
                path="baremetal/addedge"
                element={<ProtectedRoute component={AddEdge} />}
                exact
              />
              <Route
                path="baremetal/security"
                element={<ProtectedRoute component={Security} />}
                exact
              />
              <Route
                path="baremetal/kubernetescluster"
                element={<ProtectedRoute component={KubernetesCluster} />}
                exact
              />
              <Route path="baremetal/aimicrocloud" element={<ProtectedRoute component={AiMicrocloud} />} />
            </Route>
          </Routes>
        </div>
      </Router>
    </SocketContext.Provider>

  );
}

export default App;