import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import axios from "axios";
import { store } from "./store";
import { Provider } from "react-redux";

axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("login_token");
        // get token from cookie
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    })

axios.interceptors.response.use(
    (response) => {
        return response;
    }
    , (error) => {
        if (error.response.status === 401) {
            localStorage.clear();
            window.location.href = "/";
        }
        return Promise.reject(error);
    }
)


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

