import React, {useState, useEffect} from "react";
import StyledButton from "../../../Components/Element/Button/Button";
import Table from "../../../Components/Element/Table/Table";
import "./LogBox.css";
import {Close, OpenInFull} from "@mui/icons-material";

const LogBox = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    function scrollToBottom() {
        const element = document.getElementById("logTable");
        element.scrollTop = element.scrollHeight;
    }

    useEffect(() => {
        scrollToBottom()
    }, [props.logData]);


    return (
        <div id="logBox" className="csv-main">
            <div className="csv-headers">
                <div className="csv-title">Logs</div>
                <div className="csv-icons">
                    <div className="btnIcons closeBtn" onClick={props.toggleOpenState}>
                        <Close className="btnIcon"/>
                    </div>
                    <div className="btnIcons expandBtn" onClick={props.toggleFullScreenState}>
                        <OpenInFull className="btnIcon"/>
                    </div>
                </div>
            </div>
            <div className={props.isFullScreen ? "csv-box csv-table full-screen":"csv-box csv-table"}>
                <div id={"logTable"} className={props.isFullScreen ?"log-table log-full":"log-table"}>
                    <div>
                        {props.logData}
                    </div>
                </div>
            </div>
            <div className="csv-box csv-mid">
                <div className="csv-button-top">
                    <StyledButton
                        styleDetails={{
                            padding: "9px 15px",
                            borderRadius: "10px",
                            bgcolor: "white",
                            bghover: "#881630",
                            colorhover: "white",
                            color: "#881630",
                            border: "2px solid #881630"
                        }}
                        name={"Cancel"}
                        onClickFunc={props.toggleOpenState}
                    />
                </div>
            </div>
        </div>
    );
};

export default LogBox;
