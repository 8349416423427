import React, { useEffect, useState, useContext } from "react";
import { Grid } from "@mui/material";
import TileLayout from "../../Components/Element/TileLayout/TileLayout";
import { Computer, Storage, Cloud, Security } from "@mui/icons-material";
import "./baremetal.css"; // Import the CSS file
import BlockBox from "../../Components/Element/Box/Box";
import { useNavigate } from "react-router-dom";
import Table from "../../Components/Element/Table/Table";
import axios from "axios";

const kubClusterHeaders = [
    { name: "Kubernetes ID", type: "label", key: "k8sId" },
    { name: "Kubernetes Name", type: "label", key: "k8sClusterName" },
    { name: "Master Servers", type: "label", key: "masterServerIP" },
    { name: "Worker Servers", type: "label", key: "workerServersIP" },
    { name: "Health Check", type: "health", key: "healthCheck" },
    { name: "Status", type: "status", key: "status" },
];






const Baremetal = () => {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigation = useNavigate()
    useEffect(() => {
        handleClusterRefresh()
    }, [])

    const handleClusterRefresh = () => {
        setIsLoading(true);
        axios.get("/api/v1/cluster/baremetal").then((res) => {
            axios.post("/api/v1/cluster/baremetal/status/", {cluster: res.data.data})
                .then((k) => {
                    console.log(k)
                    const data = k.data.data.map((item) => {
                        console.log(item)
                        const masterIP = item.masterServerIP.map((ip) => {
                            return ip.hostname;
                        }).join(", ");
                        const workerIP = item.workerServersIP.map((ip)=>{
                            return ip.hostname;
                        }).join(",");
                        return {
                            k8sId: item.k8sId,
                            k8sClusterName: item.k8sClusterName,
                            masterServerArray: item.masterServerArray,
                            masterServerIP: masterIP,
                            workerServersArray: item.workerServersArray,
                            workerServersIP: workerIP,
                            healthCheck: item.healthCheck,
                            status: item.status,
                        };
                    });
                    setTableData(data);
                    setIsLoading(false);
                });
        });
    }
    return (
        <div className="server-box">

            <BlockBox>

                {/* <div className="server-table-cover"> */}
                <div className="server-box-title">All Clusters</div>
                <br></br>
                <Table
                    columns={kubClusterHeaders}
                    loading={isLoading}
                    customTableMessage={"No Clusters Found"}
                    column={true}
                    rows={tableData}
                />
                {/* </div> */}
            </BlockBox>

            <BlockBox>


                <div className="server-box-title">Configure Baremetal</div>
                <br />
                <br />
                <div>{" "} </div>
                <div className="baremetal-main">
                    <div className="baremetal-box">
                        <div className="server-block-header">
                            <Grid container spacing={2}>

                                <Grid item xs={4}>
                                    <TileLayout
                                        onClickFunc={() => navigation("addedge")}
                                        link="google.com"
                                        name="Add Servers"
                                        icon={<Storage />}
                                        styleDetails={{
                                            bgcolor: "white",
                                            bghover: "#f0f0f0",
                                            width: "100%",
                                            height: "200px", // Adjust the size
                                        }}
                                        className="baremetal-card" // Apply the CSS class
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TileLayout
                                        onClickFunc={() => navigation("Kubernetescluster")}
                                        name="Add Clusters"
                                        icon={<Computer />}
                                        styleDetails={{
                                            bgcolor: "white",
                                            bghover: "#f0f0f0",
                                            width: "100%",
                                            height: "200px", // Adjust the size
                                        }}
                                        className="baremetal-card" // Apply the CSS class
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TileLayout
                                        onClickFunc={() => navigation("aimicrocloud")}

                                        name="Add AI-Microcloud"
                                        icon={<Cloud />}
                                        styleDetails={{
                                            bgcolor: "white",
                                            bghover: "#f0f0f0",
                                            width: "100%",
                                            height: "200px", // Adjust the size
                                        }}
                                        className="baremetal-card" // Apply the CSS class
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>

            </BlockBox>
        </div>
    );
};

export default Baremetal;
