import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLogin: false,
  token: localStorage.getItem("login_token") || "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      localStorage.setItem("login_token", action.payload);
      state.token = action.payload;
      state.isLogin = true;
    },
    logout: (state) => {
      localStorage.clear();
      state.token = "";
      state.isLogin = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
