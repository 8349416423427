import React from 'react';
import { Link, useParams } from 'react-router-dom';
import zeblokLogo from '../../Assets/Images/logo.svg';

const WelcomePage = () => {
  const { name } = useParams();

  const pageStyles = {
    minHeight: '100vh', // Fill the entire viewport height
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#ffffff', // Set the background color of the entire page
    padding: 0, // Remove padding
    margin: 0, // Remove margin
  };

  const contentStyles = {
    flex: 1, // Fill available vertical space
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
  };

  const logoStyles = {
    position: 'absolute', // Position the logo
    top: '20px', // Adjust top position as needed
    left: '20px', // Adjust left position as needed
    maxHeight: '40px',
  };

  const h2Styles = {
    fontSize: '24px',
    color: '#333',
  };

  const pStyles = {
    fontSize: '16px',
    color: '#666',
  };

  const footerStyles = {
    textAlign: 'center',
    backgroundColor: '#ffffff',
    padding: '10px 0',
    fontSize: '12px',
    color: '#666',
    marginTop: '20px',
    borderRadius: '8px',
    width: '100%',
  };

  return (
    <div style={pageStyles}>
      <a href="#"><img src={zeblokLogo} alt="" style={logoStyles} /></a>
      <div style={contentStyles}>
        <h2 style={h2Styles}>Hey! {name}</h2>
        <p style={pStyles}>We're super excited to have you on board! Please <Link to="/" style={{ color: '#2196F3', textDecoration: 'underline' }}>Login</Link> to continue.</p>
      </div>
      <div style={footerStyles}>
        <p>Copyright © 2021 Zeblok Computational Inc. All rights reserved</p>
      </div>
    </div>
  );
};

export default WelcomePage;
