import StyledButton from "../../../Components/Element/Button/Button";
import React, {useEffect, useState} from "react";
import Dialog from '@mui/material/Dialog';

import './ViewMore.css';
import {Button, DialogActions, DialogTitle} from "@mui/material";
import axios from "axios";

const ViewMore = (props) => {
    const [open, setOpen] = useState(false);
    const data = props.tableData[props.rowIndex];


    const handleDeleteDialog = () => {
        setOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        props.deleteServer(data._id)
        setOpen(false);
        props.toggleOpenState()
    }

    return (
        <div className="csv-main">

            <Dialog
                open={open}
                onClose={handleDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure want to delete the server?"}
                </DialogTitle>

                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog}>Disagree</Button>
                    <Button onClick={handleDelete} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>

            <div className="csv-headers">
                <div className="csv-title">Server Details</div>
                <div className="csv-closeX" onClick={props.toggleOpenState}>X</div>
            </div>

            <div className="csv-box csv-table">
                <div className="csv-table-cover">
                    <div className="server-item">
                        <label className="server-item-name">Management IP</label>
                        <p className="server-item-details">{data.managementip}</p>
                    </div>

                    <div className="server-item">
                        <label className="server-item-name">Hostname</label>
                        <p className="server-item-details">{data.hostname}</p>
                    </div>

                    {/* <div className="server-item">
                        <label className="server-item-name">Data IP</label>
                        <p className="server-item-details">{data.dataip}</p>
                    </div>

                    <div className="server-item">
                        <label className="server-item-name">BMC IP</label>
                        <p className="server-item-details">{data.bmcip}</p>
                    </div> */}

                    <div className="server-item">
                        <label className="server-item-name">Username</label>
                        <p className="server-item-details">{data.username}</p>
                    </div>

                    <div className="server-item">
                        <label className="server-item-name">Password</label>
                        <p className="server-item-details">{data.password}</p>
                    </div>

                    {/* <div className="server-item">
                        <label className="server-item-name">Data Interface</label>
                        <p className="server-item-details">{data.dataInterface}</p>
                    </div>

                    <div className="server-item">
                        <label className="server-item-name">OS Installation Status</label>
                        <p className="server-item-details">{data.osInstallationStatus === "completed" ? "Completed" : data.osInstallationStatus}</p>
                    </div> */}

                    <div className="server-item">
                        <label className="server-item-name">Server Status</label>
                        <p className="server-item-details">{data.status ? "Active" : "Inactive"}</p>
                    </div>

                </div>

            </div>
            <div className="csv-box csv-mid">
                <div className="csv-button-top">

                    <StyledButton
                        styleDetails={{
                            padding: "9px 15px",
                            borderRadius: "10px",
                            bgcolor: "white",
                            bghover: "#881630",
                            colorhover: "white",
                            color: "#881630",
                            border: "2px solid #881630"
                        }}
                        name={"Delete Server"}
                        onClickFunc={handleDeleteDialog}
                    />

                    <StyledButton
                        styleDetails={{
                            padding: "9px 15px",
                            borderRadius: "10px",
                            bgcolor: "white",
                            bghover: "#881630",
                            colorhover: "white",
                            color: "#881630",
                            border: "2px solid #881630"
                        }}
                        name={"Cancel"}
                        onClickFunc={props.toggleOpenState}
                    />

                </div>
            </div>
        </div>
    )
}
export default ViewMore