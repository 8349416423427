import React, {useEffect, useState} from "react";
import './ConfigureStorage.css'
import Table from "../../../../../Components/Element/Table/Table";
import Input from "../../../../../Components/Element/Input/Input";

const ConfigureStorage = (props) => {
    return (
        <div className="clusterDetails-step-main">
            <h5 style={{color:"red"}}>* Please add the relative path of one storage only eg : sdb/</h5>
            <div className="clusterdetails-input">
                <Input
                    styled={{
                        padding: "10px 5px",
                        borderRadius: "5px",
                        margin: "10px 0 0 0",
                    }}
                    name={"Enter Storage Path"}
                    placeholder={"eg: sdb/"}
                    callback={props.setDiskPathStorage}
                />
            </div>
        </div>
    )
}

export default ConfigureStorage
