import React, { useState } from "react";
import "./Support.css"; // Import your CSS file here
import Icon from "../../Assets/Images/short_logo.png"
import close from "../../Assets/Images/close.svg"
import Axios from "axios";

const Support = () => {
    const [supportState, setSupportState] = useState(false);
    const [issueSubmitted, setIssueSubmitted] = useState(false);
    const [QueryTitle, setQueryTitle] = useState("");
    const [QueryType, setQueryType] = useState("Query"); // Set the default value to "Query"
    const [QueryMessage, setQueryMessage] = useState("");
    const [IssueId, setIssueId] = useState(""); // You might want to set this based on your API response.

    const toggleSupport = () => {
        setSupportState(!supportState);
    };

    const submitQuery = () => {
        // Construct the request body
        const requestBody = {
            title: QueryTitle,
            type: QueryType,
            details: QueryMessage,
            issueId: IssueId,
        };
        Axios.post("/api/v1/system/support", requestBody)
            .then((response) => {
                if (response.status === 200) {
                    // API request was successful
                    return response.data;
                } else {
                    // Handle errors here (e.g., show an error message)
                    throw new Error("API request failed");
                }
            })
            .then((data) => {
                // Handle the API response data as needed
                // For example, update the IssueId state based on the response
                const response = JSON.parse(data.data)
                setIssueId(response.id); // Update IssueId based on the API response
                setIssueSubmitted(true); // Set issueSubmitted to true

            })
            .catch((error) => {
                // Handle any errors that occurred during the Axios request
                console.error("API request error:", error);
                // You can also display an error message to the user
            });
    };




    return (
        <div className="support-container">
            <button className="support-btn" onClick={toggleSupport}>
                <div className="support-container-box">
                    <img src={Icon} alt="support" />
                    <span className="support-container-text">Support</span>
                </div>
            </button>

            {supportState && !issueSubmitted && (
                <div className={`support-popup ${supportState ? 'show' : ''}`}>
                    <div
                        className="support-popup-header"
                    >
                        <h5
                            className="support-popup-heading"
                        >
                            <img
                                src={Icon}
                                alt="support"
                                width="30px"
                                height="30px"
                            />
                            <span
                                className="raise-ticket-text"
                            >Raise Ticket</span>
                        </h5>
                        <img
                            src={close}
                            alt="support"
                            width="30px"
                            height="30px"
                            className="support-popup-closeimg"
                            onClick={toggleSupport}
                        />
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Title"
                        value={QueryTitle}
                        onChange={(e) => setQueryTitle(e.target.value)}
                    />
                    <label htmlFor="queryType">Type</label>
                    <select
                        id="queryType"
                        className="form-control"
                        value={QueryType}
                        onChange={(e) => setQueryType(e.target.value)}
                    >
                        <option value="Query">Query</option>
                        <option value="Bug">Bug</option>
                    </select>
                    <textarea
                        className="form-control"
                        placeholder="Enter Additional Details"
                        value={QueryMessage}
                        onChange={(e) => setQueryMessage(e.target.value)}
                        rows="5"
                    ></textarea>
                    <button className="support-submit-button" onClick={submitQuery}>
                        Submit
                    </button>
                </div>
            )}
            {supportState && issueSubmitted && (
                <div className={`support-popup ${issueSubmitted ? 'show' : ''}`}>
                    <div
                        className="support-popup-header"
                    >
                        <h4
                            className="support-popup-heading"
                        >
                            <img
                                src={Icon}
                                alt="support"
                                width="30px"
                                height="30px"
                            />
                            <span className="raise-ticket-text">Request Submitted</span>
                        </h4>
                        <img
                            src={close}
                            alt="support"
                            width="30px"
                            height="30px"
                            className="support-popup-closeimg"
                            onClick={toggleSupport}
                        />
                    </div>
                    <h6>
                        Request ID: <span>{IssueId}</span>
                    </h6>
                    <p>
                        Your request has been submitted. <br />We will get back to you
                        within 24 hours.
                    </p>
                </div>
            )}
        </div>
    );
};

export default Support;