import React, { useEffect, useState } from "react";
import "./Login.css";
import left_image from "../../Assets/Images/mainImage.svg";
import logo from "../../Assets/Images/logo.svg";
import Input from "../../Components/Element/Input/Input";
import { Lock, Email, Person, Person4Outlined } from "@mui/icons-material";
import StyledButton from "../../Components/Element/Button/Button";
import { ExitToApp } from "@mui/icons-material";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { handleLogin, handleSignup, handleVerifyEmail } from "./LoginFunction";
import SnackbarComponent from "../../Components/Element/Snackbar/Snackbar";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../slice/authSlice";

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  "& span": {
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
}));

const Login = () => {
  const toggle = () => {
    setIsLogin(!isLogin);
  };
  const dispatch = useDispatch();

  //   const isLogin = useSelector((state) => state.auth.isLogin);

  const navigation = useNavigate();
  const [isLogin, setIsLogin] = useState(true);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [fullname, setFullName] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    message: "",
    severity: "success",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [forgotPasswordClicked, setForgotPasswordClicked] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState("");
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    if (token) navigation("/launcher/eks");
  });
  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const toggleForgotPassword = () => {
    setForgotPasswordClicked(false);
    setIsLogin(!isLogin);
  };
  const Login = (token) => {
    dispatch(login(token));
  };
  return (
    <div className="login-window">
      <SnackbarComponent
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarData.message}
        severity={snackbarData.severity}
      />
      <div className="login-box login-left">
        <div className="login-content">
          <img src={logo} alt="Zeblok Logo" className="login-logo" />
          <div className="login-tagline">
            Zeblok is now an intel Partner Alliance member
          </div>
          <img
            src={left_image}
            alt="zeblok image"
            className="login-left-image"
          />
        </div>
      </div>
      {forgotPasswordClicked ? (
        <div className="login-box login-right">
          <div className="login-content login-form">
            <div className="login-welcome-title">Reset Password</div>
            <div className="login-requirement-line">
              Please enter your Registered Email Id below to send Password Reset
              Link
            </div>
            <Input
              type={"email"}
              name={"Email"}
              id={"forgot-email"}
              icon={<Email />}
              placeholder={"Enter your email"}
              callback={setVerifyEmail}
              styled={{
                padding: "10px",
                borderRadius: "5px",
                margin: "10px 0",
              }}
            />
            <div
              className="login-btn"
              onClick={() =>
                handleVerifyEmail(verifyEmail, setSnackbarOpen, setSnackbarData)
              }
            >
              <StyledButton
                styleDetails={{
                  padding: "9px 15px",
                  borderRadius: "10px",
                  bgcolor: "#881630",
                  bghover: "#ffc000",
                }}
                name={"Send"}
              />
            </div>
            <div className="login-already-account">
              Not registered yet?{" "}
              <span
                className="login-span"
                onClick={() => toggleForgotPassword()}
              >
                Create an Account
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="login-box login-right">
          {isLogin ? (
            <div className="login-content login-form">
              <div className="login-welcome-title">Welcome Back</div>
              <div className="login-requirement-line">
                Please enter your details below to login
              </div>
              <Input
                type={"email"}
                name={"Email"}
                id={"login-email"}
                icon={<Email />}
                placeholder={"Enter email or username"}
                callback={setEmail}
                styled={{
                  padding: "10px",
                  borderRadius: "5px",
                  margin: "10px 0",
                }}
              />
              <Input
                type={showPassword ? "text" : "password"}
                name={"Password"}
                id={"login-password"}
                icon={<Lock />}
                placeholder={"Password"}
                callback={setPassword}
                styled={{
                  padding: "10px",
                  borderRadius: "5px",
                  margin: "10px 0",
                }}
                toggleView={true}
                togglePasswordVisibility={handlePasswordToggle}
              />
              <div className="login-remember-section">
                <FormGroup>
                  <StyledFormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="Remember Me?"
                  />
                </FormGroup>
                <button
                  className="login-forgotPassword"
                  onClick={() => setForgotPasswordClicked(true)}
                >
                  Forgot Password?
                </button>
              </div>
              <div
                className="login-btn"
                onClick={() =>
                  handleLogin(
                    email,
                    password,
                    navigation,
                    setSnackbarOpen,
                    setSnackbarData,
                    Login
                  )
                }
              >
                <StyledButton
                  styleDetails={{
                    padding: "9px 15px",
                    borderRadius: "10px",
                    bgcolor: "#881630",
                    bghover: "#ffc000",
                  }}
                  name={"Login"}
                  endIcon={<ExitToApp />}
                />
              </div>
              <div className="login-already-account">
                Not registered yet?{" "}
                <span className="login-span" onClick={() => toggle()}>
                  Create an Account
                </span>
              </div>
            </div>
          ) : (
            <div className="login-content login-form">
              <div className="login-welcome-title">Create an Account</div>
              <div className="login-requirement-line">
                Please enter your details below to create account
              </div>
              <Input
                type={"text"}
                name={"Full Name"}
                id={"signup-name"}
                icon={<Person />}
                placeholder={"Enter your full name"}
                callback={setFullName}
                styled={{
                  padding: "10px",
                  borderRadius: "5px",
                  margin: "10px 0",
                }}
              />
              <Input
                type={"text"}
                name={"Username"}
                id={"signup-username"}
                icon={<Person4Outlined />}
                placeholder={"Enter email or username"}
                callback={setUserName}
                styled={{
                  padding: "10px",
                  borderRadius: "5px",
                  margin: "10px 0",
                }}
              />
              <Input
                type={"email"}
                name={"Email"}
                id={"signup-email"}
                icon={<Email />}
                placeholder={"Enter email or username"}
                callback={setEmail}
                styled={{
                  padding: "10px",
                  borderRadius: "5px",
                  margin: "10px 0",
                }}
              />
              <Input
                type={showPassword ? "text" : "password"}
                name={"Password"}
                id={"signup-password"}
                icon={<Lock />}
                placeholder={"Password"}
                callback={setPassword}
                styled={{
                  padding: "10px",
                  borderRadius: "5px",
                  margin: "10px 0",
                }}
                toggleView={true}
                togglePasswordVisibility={handlePasswordToggle}
              />
              <div className="login-remember-section">
                <FormGroup>
                  <StyledFormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="Remember Me?"
                  />
                </FormGroup>
              </div>
              <div
                className="login-btn"
                onClick={() =>
                  handleSignup(
                    { name: fullname, username, email, password },
                    navigation,
                    setSnackbarOpen,
                    setSnackbarData
                  )
                }
              >
                <StyledButton
                  styleDetails={{
                    padding: "9px 15px",
                    borderRadius: "10px",
                    bgcolor: "#881630",
                    bghover: "#ffc000",
                  }}
                  name={"Sign Up"}
                  endIcon={<ExitToApp />}
                />
              </div>
              <div className="login-already-account">
                Already Have a Account?{" "}
                <span className="login-span" onClick={() => toggle()}>
                  Login
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Login;
