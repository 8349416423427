import React, {useState} from "react";
import Table from "../../../../../Components/Element/Table/Table";

const tableHeaders = [
    {name: "", type: "checkbox", editable: true, key: "_id", wid: 10},
    {name: "BMC IP", type: "label", key: "bmcip"},
    {name: "Management IP", type: "label", key: "managementip"},
];

const SelectServer = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    return (
        <div className="MasterServer-step-main">
            <Table
                columns={tableHeaders}
                loading={isLoading}
                column={true}
                rows={props.tableData}
                customTableMessage={"No Compatible Server Available"}
                setCheckBoxData={props.setCheckBoxSelected}
                checkboxData={props.checkBoxSelected}
                maxCheck={1}
            />
        </div>
    )
}

export default SelectServer