import React, {useEffect, useState, useContext} from "react";
import StyledButton from "../../Components/Element/Button/Button";
import BlockBox from "../../Components/Element/Box/Box";
import Searchbar from "../../Components/Element/Searchbar/Searchbar";
import "./KubernetesCluster.css";
import AddIcon from "@mui/icons-material/Add";
import Table from "../../Components/Element/Table/Table";
import SpringModal from "../../Components/Element/Modal/Modal";
import axios from "axios";
import AddCluster from "./AddCluster/AddCluster";
import {CachedOutlined, ReceiptLong} from "@mui/icons-material";
import LogBox from "../../Components/Element/LogBox/LogBox";
import {SocketContext} from "../../context/socket";
import AddWorkerToCluster from "./AddWorkerToCluster/AddWorkerToCluster";

const kubClusterHeaders = [
    {name: "Kubernetes ID", type: "label", key: "k8sId"},
    {name: "Kubernetes Name", type: "label", key: "k8sClusterName"},
    {name: "Master Servers", type: "label", key: "masterServerIP"},
    {name: "Worker Servers", type: "label", key: "workerServersIP"},
    {name: "Health Check", type: "health", key: "healthCheck"},
    {name: "Status", type: "status", key: "status"},
];

const KubernetesCluster = () => {
    const [tableData, setTableData] = useState([]);
    const [openAddClusterModel, setOpenAddClusterModel] = useState(false);
    const [openAddWorkerModel, setOpenAddWorkerModel] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openLogModel, setOpenLogModel] = useState(false);
    const [logData, setLogData] = useState([]);

    const socket = useContext(SocketContext)

    const getClustersData = async () => {
        setIsLoading(true);
        // console.log(isLoading, "pl");
        axios.get("/api/v1/cluster/baremetal").then((res) => {
            const data = res.data.data.map((item) => {
                const masterIP = item.masterServerIP.map((ip) => {
                    return ip.hostname;
                }).join(", ");
                const workerIP = item.workerServersIP.map((ip)=>{
                    return ip.hostname;
                }).join(",");
                return {
                    k8sId: item.k8sId,
                    k8sClusterName: item.k8sClusterName,
                    masterServerArray: item.masterServerArray,
                    masterServerIP: masterIP,
                    workerServersArray: item.workerServersArray,
                    workerServersIP: workerIP,
                    healthCheck: item.healthCheck,
                    status: item.status,
                };
            });
            setTableData(data);
            setIsLoading(false);
        });
    };

    const handleAddClusterToggle = () => {
        setOpenAddClusterModel(!openAddClusterModel);
        getClustersData();
    };

    const handleAddWorkerToggle = () => {
        setOpenAddWorkerModel(!openAddWorkerModel);
        getClustersData();
    };

    const toggleFullScreenState = () => {
        setIsFullScreen(!isFullScreen);
    };

    const handleLogView = () => {
        setOpenLogModel(!openLogModel);
    }

    socket.on('cluster_logs', (data) => {
        data = data.data;
        console.log(logData, "logData", data)
        const newData = logData + '\n' + data;
        setLogData(newData);
    })

    useEffect(() => {
        getClustersData();
    }, []);
    const handleClusterRefresh = () => {
        setIsLoading(true);
        axios.get("/api/v1/cluster/baremetal").then((res) => {
            axios.post("/api/v1/cluster/baremetal/status/", {cluster: res.data.data})
                .then((k) => {
                    console.log(k)
                    const data = k.data.data.map((item) => {
                        console.log(item)
                        const masterIP = item.masterServerIP.map((ip) => {
                            return ip.hostname;
                        }).join(", ");
                        const workerIP = item.workerServersIP.map((ip)=>{
                            return ip.hostname;
                        }).join(",");
                        return {
                            k8sId: item.k8sId,
                            k8sClusterName: item.k8sClusterName,
                            masterServerArray: item.masterServerArray,
                            masterServerIP: masterIP,
                            workerServersArray: item.workerServersArray,
                            workerServersIP: workerIP,
                            healthCheck: item.healthCheck,
                            status: item.status,
                        };
                    });
                    setTableData(data);
                    setIsLoading(false);
                });
        });
    }
    return (
        <>
            <SpringModal open={openAddClusterModel}>
                <AddCluster setOpen={setOpenAddClusterModel}/>
            </SpringModal>
            <SpringModal open={openAddWorkerModel}>
                <AddWorkerToCluster setOpen={setOpenAddWorkerModel}/>
            </SpringModal>
            <SpringModal styleModal={isFullScreen?{width: "100vw", lgWidth: "100vw", mdWidth: "100vw",height:"100vh"}:{width: "80vw", lgWidth: "80vw", mdWidth: "97vw"}} open={openLogModel}
                         setOpen={setOpenLogModel}>
                <LogBox logData={logData} isFullScreen={isFullScreen} toggleFullScreenState={toggleFullScreenState} toggleOpenState={handleLogView}/>
            </SpringModal>
            <div className="server-main">
                <div className="server-box">
                    <BlockBox>
                        <div className="server-block-header">
                            <div className="server-box-title">All Baremetal Clusters</div>
                            <div className="server-block-header-right">
                                {/* <div style={{marginRight: "15px"}}>
                                    <Searchbar/>
                                </div> */}

                                <StyledButton
                                    styleDetails={{
                                        padding: "9px 15px",
                                        borderRadius: "10px",
                                        bgcolor: "#881630",
                                        bghover: "#ffc000",
                                        margin: "0px 5px",
                                    }}
                                    disabled={isLoading}
                                    name={"Create New Cluster"}
                                    endIcon={<AddIcon/>}
                                    onClickFunc={handleAddClusterToggle}
                                />
                                <StyledButton
                                    styleDetails={{
                                        padding: "9px 15px",
                                        borderRadius: "10px",
                                        bgcolor: "#881630",
                                        bghover: "#ffc000",
                                        margin: "0px 5px",
                                    }}
                                    name={"Create Worker"}
                                    endIcon={<AddIcon/>}
                                    onClickFunc={handleAddWorkerToggle}
                                />
                                <StyledButton
                                    styleDetails={{
                                        padding: "9px 15px",
                                        borderRadius: "10px",
                                        bgcolor: "#881630",
                                        bghover: "#ffc000",
                                        margin: "0px 5px",
                                    }}
                                    name={"Refresh Cluster"}
                                    endIcon={<CachedOutlined/>}
                                    onClickFunc={handleClusterRefresh}
                                />
                                <StyledButton
                                    styleDetails={{
                                        padding: "9px 15px",
                                        borderRadius: "10px",
                                        bgcolor: "#881630",
                                        bghover: "#ffc000",
                                        margin: "0px 5px"
                                    }}
                                    disabled={logData.length === 0}
                                    name={"View Logs"}
                                    endIcon={<ReceiptLong/>}
                                    onClickFunc={logData.length === 0 ? () => {
                                    } : handleLogView}
                                />
                            </div>
                        </div>

                        <div className="server-table-cover">
                            <Table
                                columns={kubClusterHeaders}
                                loading={isLoading}
                                customTableMessage={"No Clusters Found"}
                                column={true}
                                rows={tableData}
                            />
                        </div>
                    </BlockBox>
                </div>
            </div>
        </>
    );
};

export default KubernetesCluster;
