import React, {useEffect, useState, useContext} from "react";
import StyledButton from "../../Components/Element/Button/Button";
import BlockBox from "../../Components/Element/Box/Box";
import Searchbar from "../../Components/Element/Searchbar/Searchbar";
import "./AiMicrocloud.css";
import AddIcon from "@mui/icons-material/Add";
import Table from "../../Components/Element/Table/Table";
import axios from "axios";
import SpringModal from "../../Components/Element/Modal/Modal";
import LogBox from "../../Components/Element/LogBox/LogBox";
import ChooseCluster from "./ChooseCluster/ChooseCluster";
import {CachedOutlined, ReceiptLong} from "@mui/icons-material";
import {SocketContext} from "../../context/socket";

const kubClusterHeaders = [
    {name: "Kubernetes ID", type: "label", key: "k8sId"},
    {name: "Kubernetes Name", type: "label", key: "k8sClusterName"},
    {name: "Health Check", type: "health", key: "healthCheck"},
    {name: "Server Status", type: "status", key: "status"},
    {name: "MicroCloud", type: "status", key: "isAiMicroCloudInstalled"},
];

const AiMicrocloud = () => {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openLogModel, setOpenLogModel] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [logData, setLogData] = useState([]);
    const [openMicroCloudModel, setOpenMicroCloudModel] = useState(false);

    const socket = useContext(SocketContext)

    const getClustersData = async () => {
        setIsLoading(true);
        // console.log(isLoading, "pl");
        axios.get("/api/v1/cluster/baremetal")
            .then((res) => {
                const data = res.data.data.map((item) => {
                    return {
                        k8sId: item.k8sId,
                        k8sClusterName: item.k8sClusterName,
                        healthCheck: item.healthCheck,
                        status: item.status,
                        isAiMicroCloudInstalled: item.isAiMicroCloudInstalled,
                    };
                });
                setTableData(data);
                setIsLoading(false);
            });
    };

    socket.on('microcloud_logs', (data) => {
        data = data.data;
        console.log(logData, "logData", data)
        const newData = logData + '\n' + data;
        setLogData(newData);
    })

    const handleAddMicroCloudToggle = () => {
        setOpenMicroCloudModel(!openMicroCloudModel);
        getClustersData();
    };

    const toggleFullScreenState = () => {
        setIsFullScreen(!isFullScreen);
    };

    const handleMicroCloudRefresh = () => {
        setIsLoading(true);
        axios.get("/api/v1/cluster/baremetal").then((res) => {
            axios.post("/api/v1/cluster/baremetal/refreshMicrocloud", {cluster: res.data.data})
                .then((k) => {
                    const data = k.data.data.map((item) => {
                        return {
                            k8sId: item.k8sId,
                            k8sClusterName: item.k8sClusterName,
                            healthCheck: item.healthCheck,
                            status: item.status,
                            isAiMicroCloudInstalled: item.isAiMicroCloudInstalled,
                        };
                    });
                    setTableData(data);
                    setIsLoading(false);
                });
        });
        // checkMicroCloud()
    }

    const handleLogView = () => {
        setOpenLogModel(!openLogModel);
    }

    useEffect(() => {
        getClustersData();
    }, []);
    return (
        <>
            <SpringModal open={openMicroCloudModel}>
                <ChooseCluster setOpen={setOpenMicroCloudModel}/>
            </SpringModal>
            <SpringModal styleModal={isFullScreen?{width: "100vw", lgWidth: "100vw", mdWidth: "100vw",height:"100vh"}:{width: "80vw", lgWidth: "80vw", mdWidth: "97vw"}} open={openLogModel}
                         setOpen={setOpenLogModel}>
                <LogBox logData={logData} isFullScreen={isFullScreen} toggleFullScreenState={toggleFullScreenState} toggleOpenState={handleLogView}/>
            </SpringModal>
            <div className="server-main">
                <div className="server-box">
                    <BlockBox>
                        <div className="server-block-header">
                            <div className="server-box-title">Configure Ai-Microcloud</div>
                            <div className="server-block-header-right">
                                <div style={{marginRight: "15px"}}>
                                    <Searchbar/>
                                </div>

                                <StyledButton
                                    styleDetails={{
                                        padding: "9px 15px",
                                        borderRadius: "10px",
                                        bgcolor: "#881630",
                                        bghover: "#ffc000",
                                        margin: "0px 5px",
                                    }}
                                    name={"Create Ai-Microcloud"}
                                    // disabled={checked}
                                    endIcon={<AddIcon/>}
                                    onClickFunc={handleAddMicroCloudToggle}
                                    // onClickFunc={checked ? () => {
                                    // } : handleAddMicroCloudToggle}
                                />
                                <StyledButton
                                    styleDetails={{
                                        padding: "9px 15px",
                                        borderRadius: "10px",
                                        bgcolor: "#881630",
                                        bghover: "#ffc000",
                                        margin: "0px 5px",
                                    }}
                                    name={"Refresh Status"}
                                    endIcon={<CachedOutlined/>}
                                    onClickFunc={handleMicroCloudRefresh}
                                />
                                <StyledButton
                                    styleDetails={{
                                        padding: "9px 15px",
                                        borderRadius: "10px",
                                        bgcolor: "#881630",
                                        bghover: "#ffc000",
                                        margin: "0px 5px"
                                    }}
                                    disabled={logData.length === 0}
                                    name={"View Logs"}
                                    endIcon={<ReceiptLong/>}
                                    onClickFunc={logData.length === 0 ? () => {
                                    } : handleLogView}
                                />
                            </div>
                        </div>

                        <div className="server-table-cover">
                            <Table
                                columns={kubClusterHeaders}
                                loading={isLoading}
                                customTableMessage={"No Clusters Found"}
                                column={true}
                                rows={tableData}
                            />
                        </div>
                    </BlockBox>
                </div>
            </div>
        </>
    );
};

export default AiMicrocloud;
